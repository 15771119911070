<template>
  <section>
    <!-- <HeaderSection :title="$t('common.the_museum')" /> -->
    <client-only>
      <PesaroMuseum class="overflow-hidden" />
    </client-only>
  </section>
</template>

<script setup></script>
