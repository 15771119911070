<template>
  <section class="min-h-[504px]">
    <NewsHeaderSection
      :title="$t('common.news')"
      :customDateClicked="customDateClicked"
      :view="view"
      @customdateselected="showCalendar"
      @view="setView"
      @update-date="setDates"
    />
    <div
      class="absolute inset-0 bg-black bg-opacity-50 z-[10000]"
      @click="hideMobileMenu"
      v-show="hasOverlay"
    ></div>
    <div class="menu__body" hidden="hidden">
      <div class="menu__header">
        <div class="flex">
          <h5
            @click="customDateClicked = !customDateClicked"
            class="font-medium no-underline text-[22px] leading-[32px] no-border font-myriad-pro"
          >
            {{ $t("common.select_date") }}
          </h5>
          <button class="close-btn left-[90%] absolute" title="Close">
            <Close @click="hideMobileMenu" />
          </button>
        </div>
        <ul>
          <li
            class="font-medium no-underline text-[16px] leading-[32px] pt-[18px] pb-[18px] font-myriad-pro"
            @click="showCalendar('week')"
          >
            {{ $t("common.this_week") }}
          </li>
          <li
            class="font-medium no-underline text-[16px] leading-[32px] pt-[18px] pb-[18px] font-myriad-pro"
            @click="showCalendar('3months')"
          >
            {{
              $t("common.last_months", {
                month: "3 ",
                interpolation: { prefix: "[:", suffix: "]" },
              })
            }}
          </li>
          <li
            class="calendarlink font-medium no-underline text-[16px] leading-[32px] pt-[18px] pb-[18px] font-myriad-pro"
          >
            <div @click="customDateClicked = !customDateClicked">
              {{ $t("common.custom_date") }}
              <span style="float: right">
                <ArrowDown
                  class="transition-all"
                  :class="customDateClicked ? 'rotate-180' : 'rotate-0'"
                />
              </span>
            </div>
            <Datepicker v-if="customDateClicked" @update-date="setDates" />
          </li>
          <li v-if="filtered">
            <Btn class="w-full mt-2" size="sm" @click="clearFilter">
              {{ $t("common.clear_filter") }}
            </Btn>
          </li>
        </ul>
      </div>
      <div class="container px-4 mx-auto">
        <button
          class="text-white buttonApply"
          @click="filterPost"
          v-if="customDateClicked"
        >
          {{ $t("common.apply") }}
        </button>
      </div>
    </div>

    <NotFound v-if="posts.length == 0 && !loading" />
    <div
      class="container py-6 min-h-[504px]"
      v-else-if="posts.length && isSizeUpdated"
    >
      <XyzTransitionGroup
        id="news-row"
        :class="[
          showCard
            ? 'grid grid-cols-1 gap-x-3 gap-y-[30px] md:grid-cols-2 md:gap-x-6 xl:gap-x-8 lg:gap-y-8 mt-8'
            : '',
        ]"
        xyz="fade duration-5 appear-front-3 small-3 appear-small-0 stagger-0"
        appear-visible
      >
        <div
          v-for="(post, key) in posts"
          :key="key"
          :class="
            showCard &&
            (key === posts.length - 1 || key === posts.length - 2) &&
            !isMobile
              ? ''
              : 'border-b border-[#EAEDF3]'
          "
        >
          <PostItem :post="post" :view="view" />
        </div>
      </XyzTransitionGroup>

      <!-- indicator: load more -->
    </div>
    <div class="container py-6">
      <Spinner
        v-if="loading"
        :class="[currentPage == 1 ? 'min-h-[504px]' : 'mb-20']"
        class="m-auto animate-spin !mt-10 w-8 h-8"
      />
      <div
        class="text-center relative my-6 !min-h-[50px] w-[100%] border-b border-[#EAEDF3] flex justify-center items-center mb-[100px]"
        v-if="showMore && isSizeUpdated"
      >
        <button
          class="text-black text-sm font-semibold absolute uppercase m-auto !bg-[#F4F4F4] bottom-[-25px] border-1 rounded-full pl-10 pr-10 pt-3 pb-3 !hover:text-black"
          @click="checkFilterWitMore()"
          v-if="showMore"
        >
          {{ $t("common.see_more") }}
        </button>
      </div>
    </div>
  </section>
</template>
<script setup>
import ArrowDown from "@/assets/svg/arrow-down-white.svg?component";
import Close from "@/assets/svg/close.svg?component";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();

const customDateClicked = ref(false);
const view = ref("list");
const closeMenu = ref(true);
const hasOverlay = ref(false);
const filtered = ref(false);
const posts = ref([]);
const loading = ref(null);
const currentPage = ref(null);
const lastPage = ref(null);
const meta = ref(null);
const fromdate = ref("");
const todate = ref("");
const showMore = ref(true);
const isMobile = ref(false);
const filterInmobile = ref(false);
const showCard = ref(false);
const isSizeUpdated = ref(false);

try {
  const dataResponse = await useAPI("api/front/posts?per_page=10");

  const { meta, data } = dataResponse.data._rawValue;
  if (meta.current_page == meta.last_page) {
    showMore.value = false;
  }

  posts.value = data;
  loading.value = true;
  currentPage.value = meta.current_page;
  lastPage.value = meta.last_page;
  meta.value = meta;
} catch (e) {
  console.log(e, "error");
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

watch(
  customDateClicked,
  (newValue, oldValue) => {
    if (newValue) {
      // customDateClicked.value = false;
      // const menu = document.querySelector(".menu__body");
      // menu.setAttribute("hidden", "");
      // hasOverlay.value = false;
    }
  },
  { deep: true }
);

onMounted(() => {
  if (import.meta.browser) {
    updateSize();
    window.addEventListener("resize", updateSize);
    document.addEventListener(
      "click",
      function (e) {
        if (e.target.getAttribute("id") == "header") {
          const menu = document.querySelector(".menu__body");
          menu.setAttribute("hidden", "");
        }
      },
      false
    );

    if (isMobile.value) {
      filterInmobile.value = true;
      view.value = "card";
    }

    const button = document.querySelector(".menu__button");

    if (button) {
      button.addEventListener("click", showMenu);
    }
  }
});

onBeforeUnmount(() => {
  if (process.browser) {
    const button = document.querySelector(".menu__button");
    if (button) {
      button.removeEventListener("click", showMenu);
      window.removeEventListener("resize", updateSize);
    }
  }
});

function showMenu() {
  hasOverlay.value = true;
  const button = document.querySelector(".menu__button");
  const menu = document.querySelector(".menu__body");
  button.setAttribute("hidden", "");
  menu.removeAttribute("hidden");
}

function updateSize() {
  if (window.innerWidth <= 767) {
    isMobile.value = true;
    filterInmobile.value = true;
    view.value = "card";
    showCard.value = true;
  } else {
    filterInmobile.value = false;
    if (view.value == "card" && isMobile.value) {
      view.value = "list";
      showCard.value = false;
    }

    isMobile.value = false;
  }
  loading.value = false;
  isSizeUpdated.value = true;
}

function clearFilter() {
  hideMobileMenu();
  filtered.value = false;
  currentPage.value = 1;
  loadMore();
}

function hideMobileMenu() {
  hasOverlay.value = false;
  if (!filtered.value) {
    customDateClicked.value = false;
  }
  const menu = document.querySelector(".menu__body");
  menu.setAttribute("hidden", "");
}

function showCalendar(type) {
  if (customDateClicked.value) {
    customDateClicked.value = false;
    return;
  }
  hideMobileMenu();
  currentPage.value = 1;
  if (type == "3months") {
    let d = new Date(new Date().setMonth(new Date().getMonth() - 3));
    fromdate.value = d.toISOString().slice(0, 10);
    todate.value = new Date().toISOString().slice(0, 10);
    filterPost();
  } else {
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    fromdate.value = firstday.toISOString().slice(0, 10);
    todate.value = lastday.toISOString().slice(0, 10);
    filterPost();
  }
}

function toggleDateClicked() {
  hasOverlay.value = false;
  customDateClicked.value = !customDateClicked.value;
}

function setView(e) {
  view.value = e;
  if (view.value == "card") {
    showCard.value = true;
  } else {
    showCard.value = false;
  }
}

function setDates(e) {
  if (!isMobile.value) {
    filtered.value = true;
  }
  currentPage.value = 1;
  if (e.start) {
    fromdate.value = new Date(e.start).toISOString().split("T")[0];
    todate.value = new Date(e.end).toISOString().split("T")[0];
  } else {
    fromdate.value = "";
    todate.value = "";
  }
  if (fromdate.value !== "" && !filterInmobile.value) {
    filterPost();
  }
  if (fromdate.value == "" && !filterInmobile.value) {
    loadMore();
  }
}

function checkFilterWitMore() {
  currentPage.value = currentPage.value + 1;
  if (fromdate.value !== "") {
    filterPost();
  }
  if (fromdate.value == "") {
    loadMore();
  }
}

function filterPost() {
  loading.value = true;
  showMore.value = false;
  hasOverlay.value = false;
  customDateClicked.value = false;
  filtered.value = true;
  hideMobileMenu();
  if (currentPage.value == 1) {
    posts.value = [];
  }
  try {
    useAPI(
      `api/front/posts?published_date[from]=${fromdate.value}&published_date[to]=${todate.value}&per_page=10&page=${currentPage.value}`
    ).then(({ data }) => {
      const responseData = data.value;
      if (responseData.data.length == 0) {
        showMore.value = false;
      }

      if (currentPage.value == 1) {
        posts.value = responseData.data;
      } else {
        alert();
        posts.value = [...posts.value, ...responseData.data];
      }

      if (responseData.meta.current_page == responseData.meta.last_page) {
        showMore.value = false;
      } else {
        showMore.value = true;
      }

      loading.value = false;
      customDateClicked.value = false;
    });
  } catch (error) {
    console.error(error);
  }
}
function loadMore() {
  showMore.value = false;
  loading.value = true;
  try {
    if (currentPage.value == 1) {
      posts.value = [];
    }
    useAPI(`api/front/posts?page=${currentPage.value}&per_page=10`).then(
      ({ data }) => {
        const responseData = data.value;
        currentPage.value = responseData.meta.current_page;
        if (currentPage.value == 1) {
          posts.value = responseData.data;
        } else {
          posts.value = [...posts.value, ...responseData.data];
        }
        if (responseData.meta.current_page == responseData.meta.last_page) {
          showMore.value = false;
        } else {
          showMore.value = true;
        }
        setTimeout(() => {
          loading.value = false;
          hideMobileMenu();
        }, 100);
      }
    );
  } catch (error) {
    console.error(error);
  }
}
</script>

<style lang="postcss">
#news-row div:last-child {
  border: none !important;
}
a:hover {
  color: #000;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .buttonApply {
    padding: 10px;
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin: auto;
    width: 350px;
    background: #13b973;
    border-radius: 10px;
    border: 1px 0px 0px 0px;
    /* top: 560px; */
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 0;
  }
}

.menu__body {
  --x: 0;
  --y: 0;
  --z: 0;
  height: 80vh;
  background: white;
  display: block;
  width: 100%;
  padding-bottom: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -9px 50px -30px black;
  font-family: "Montserrat", sans-serif;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  transform: translate3d(var(--x), var(--y), var(--z));
  transition: 0.2s cubic-bezier(0.33, 1, 0.53, 1);
}

.menu__body[hidden] {
  display: none;
}

.menu__body > *:not(:last-child) {
  border-bottom: 2px solid var(--neutral);
}

.menu__header {
  padding: 15px 20px;
}

.menu__header label div {
  width: 15px;
  height: 15px;
  border: 2px solid var(--dark);
  border-radius: 50%;
  position: relative;
  transform: rotate(5.5rad);
}

.menu__header label div:after {
  content: "";
  width: 2px;
  height: 10px;
  background: var(--dark);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.menu__header p {
  font-size: 18px;
  font-weight: bold;
  vertical-align: center;
  white-space: nowrap;
}

.menu__header h3 {
  font-weight: normal;
}

.menu__header .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: var(--neutral-dark);
  border-radius: 50%;
  cursor: pointer;
}

.menu__header .close-btn div {
  width: 15px;
  height: 3px;
  background: var(--light);
  position: relative;
  transform: rotate(5.5rad);
}

.menu__header .close-btn div:before {
  content: "";
  width: 3px;
  height: 15px;
  background: var(--light);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu__header ul {
  color: #000;
  font-weight: 600;
  font-size: larger;
  text-align: left;
  width: -webkit-fill-available;
}

.menu__header ul li {
  border-bottom: 1px solid #eaedf3;
  font-weight: 500;
}

.menu__links {
  display: flex;
  padding: 10px 15px;
  font-weight: bold;
  overflow-x: auto;
}

.menu__links a {
  flex: 1 1 1px;
  padding: 10px 8px;
  color: var(--dark);
  text-decoration: none;
  white-space: nowrap;
}

.menu__contact {
  display: flex;
  padding: 8px;
}

.menu__contact svg {
  width: 100%;
  fill: var(--color);
}

.menu__contact a {
  flex: 1 1 30%;
  padding: 5px;
  margin: 5px;
  border-radius: 8px;
  background: var(--neutral);
  color: var(--color);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
</style>
