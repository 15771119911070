import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g id=\"__arrow-down-s-line\" opacity=\"0.4\" clip-path=\"url(#__clip0_5171_16442)\"><g id=\"__Group\"><path id=\"__Vector\" d=\"M9.99962 10.9767L14.1246 6.85168L15.303 8.03002L9.99962 13.3334L4.69629 8.03002L5.87462 6.85168L9.99962 10.9767Z\" fill=\"currentColor\"></path></g></g><defs><clipPath id=\"__clip0_5171_16442\"><rect width=\"20\" height=\"20\" fill=\"white\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }