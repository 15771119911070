import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "18",
  viewBox: "0 0 16 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M5.42857 0.84375C5.42857 0.376172 5.04643 0 4.57143 0C4.09643 0 3.71429 0.376172 3.71429 0.84375V2.25H2.28571C1.025 2.25 0 3.25898 0 4.5V5.0625V6.75V15.75C0 16.991 1.025 18 2.28571 18H13.7143C14.975 18 16 16.991 16 15.75V6.75V5.0625V4.5C16 3.25898 14.975 2.25 13.7143 2.25H12.2857V0.84375C12.2857 0.376172 11.9036 0 11.4286 0C10.9536 0 10.5714 0.376172 10.5714 0.84375V2.25H5.42857V0.84375ZM1.71429 6.75H14.2857V15.75C14.2857 16.0594 14.0286 16.3125 13.7143 16.3125H2.28571C1.97143 16.3125 1.71429 16.0594 1.71429 15.75V6.75Z",
      fill: "white"
    }, null, -1)
  ])))
}
export default { render: render }