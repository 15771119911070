<template>
  <section class="test-ride">
    <HeaderSection :title="$t('common.test_ride_inquiry.title')" />
    <div class="container py-12">
      <div :class="testDrivePageContent ? 'divide-y-2 divide-gray-200' : ''">
        <!-- Global Contact option -->
        <div
          v-if="testDrivePageContent"
          class="pb-12 mt-12 lg:grid lg:grid-cols-3 lg:gap-8"
        >
          <h2
            class="mt-0 text-2xl font-extrabold text-gray-900 sm:text-3xl capitalize-first"
            v-text="testDrivePageContent.title"
          />
          <div class="col-span-2 mt-8 lg:mt-0">
            <div
              class="test-drive-info"
              v-html="testDrivePageContent.content"
            />
          </div>
        </div>
        <div class="pt-12 lg:grid lg:grid-cols-3 lg:gap-8">
          <h2
            class="text-2xl font-extrabold text-gray-900 sm:text-3xl"
            v-text="$t('common.test_ride_inquiry.registration')"
          />
          <form
            id="test_ride_form"
            action="#"
            method="POST"
            class="grid grid-cols-1 col-span-2 mt-6 gap-y-6 sm:grid-cols-2 sm:gap-x-8 lg:mt-0"
            @submit.prevent="onSubmit"
          >
            <div class="sm:col-span-2">
              <label
                for="contact_name"
                class="block text-sm font-medium text-gray-700"
                v-text="$t('common.name')"
              />
              <div class="mt-1">
                <input
                  id="contact_name"
                  v-model="form.name"
                  data-cons-subject="name"
                  type="text"
                  name="name"
                  autocomplete="name"
                  class="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm"
                  :class="{ 'is-invalid': errorsObj && errorsObj.name }"
                  @input="clearValidation('name')"
                />
                <p
                  v-if="errorsObj && errorsObj.name"
                  class="mt-1 text-sm text-red-500"
                  v-text="errorsObj.name[0]"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="contact_phone_number"
                class="block text-sm font-medium text-gray-700"
                v-text="$t('common.phone')"
              />
              <div class="relative mt-1 rounded-md shadow-sm">
                <VueTelInput
                  id="phone"
                  v-model="form.phone"
                  input-id="contact_phone_number"
                  mode="international"
                  autocomplete="phone"
                  :default-country="mainStore.country"
                  :input-options="options"
                  :class="[
                    { 'is-invalid': errorsObj && errorsObj.phone },
                    'phone',
                  ]"
                  @input="clearValidation('phone')"
                />
              </div>
              <p
                v-if="errorsObj && errorsObj.phone"
                class="mt-1 text-sm text-red-500"
                v-text="errorsObj.phone[0]"
              />
            </div>
            <div class="sm:col-span-2">
              <label
                for="contact_email"
                class="block text-sm font-medium text-gray-700"
                v-text="$t('common.email')"
              />
              <div class="mt-1">
                <input
                  id="contact_email"
                  v-model="form.email"
                  data-cons-subject="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  class="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm"
                  :class="{ 'is-invalid': errorsObj && errorsObj.email }"
                  @input="clearValidation('email')"
                />
                <p
                  v-if="errorsObj && errorsObj.email"
                  class="mt-1 text-sm text-red-500"
                  v-text="errorsObj.email[0]"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="bike"
                class="block text-sm font-medium text-gray-700"
                v-text="$t('common.test_ride_inquiry.model_you_want_to_drive')"
              />

              <div class="mt-1">
                <select
                  v-model="selected"
                  name="products"
                  class="block w-full px-4 py-3 border-gray-300 rounded-md shadow-sm"
                  :class="{ 'is-invalid': errorsObj && errorsObj.product_id }"
                  @input="clearValidation('selected')"
                >
                  <option
                    v-for="(product, index) in products"
                    :key="index"
                    :value="product"
                  >
                    {{ product.name }}
                  </option>
                </select>
                <p
                  v-if="errorsObj && errorsObj.product_id"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.product_id[0]"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="other_information"
                class="block text-sm font-medium text-gray-700"
                v-text="$t('common.test_ride_inquiry.other_information')"
              />
              <div class="relative mt-1">
                <textarea
                  id="other_information"
                  v-model="form.message"
                  name="other_information"
                  rows="4"
                  class="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm min-h-40 lg:min-h-32 max-h-64"
                  :class="{ 'is-invalid': errorsObj && errorsObj.message }"
                  @input="clearValidation('message')"
                />
                <p
                  v-if="errorsObj && errorsObj.message"
                  class="mt-1 text-sm text-red-500"
                  v-text="errorsObj.message[0]"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                class="w-full p-2 text-lg border border-transparent cursor-pointer contact--checkbox"
                :class="{
                  'border-red-500 bg-red-50 text-red-400':
                    errorsObj && errorsObj.accept_terms,
                }"
              >
                <input
                  id="contact_tos"
                  v-model="form.accept_terms"
                  type="checkbox"
                  class="me-3 cursor-pointer relative text-primary-500 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 focus:ring-2 mb-[.1rem]"
                  data-cons-preference="terms-and-conditions"
                  @input="clearValidation('accept_terms')"
                />
                <!-- eslint-disable vue/no-v-html -->
                <span
                  ref="link"
                  class="terms contact__checkmark"
                  v-html="
                    $t('common.test_ride_inquiry.accept_data_management_info')
                      .replace('legal', 'javascript:void(0)')
                      .replace(
                        'privacy-policy',
                        policyData ? 'privacy-policy' : 'privacy-policy-remove'
                      )
                  "
                />
                <!-- eslint-enable -->
              </label>
            </div>

            <ErrorBag :errorsObj="errorsObj" class="mt-4 sm:col-span-2" />

            <div class="flex justify-center md:justify-start">
              <vue-hcaptcha
                ref="invisibleHcaptcha"
                size="invisible"
                :sitekey="$config.HCAPTCHA_SITE_KEY"
                theme="light"
                :language="mainStore.locale"
                @verify="onVerify"
                @expired="onExpire"
                @challengeExpired="onExpire"
                @error="onError"
              />
            </div>

            <div class="sm:col-span-2">
              <Btn
                id="contact_submit"
                :disabled="isSending"
                type="submit"
                size="xl"
                block
                color="primary"
                class="flex items-center justify-center"
              >
                <div class="flex items-center justify-center space-x-2">
                  <SpinnerIcon v-if="isSending" class="w-4 h-4 animate-spin" />
                  <span class="font-bold text-white uppercase font-myriad-pro">
                    {{ $t("common.submit") }}</span
                  >
                </div>
              </Btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { VueTelInput } from "vue-tel-input";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import PrivacyPolicyTestDriveModal from "~/modals/PrivacyPolicyTestDriveModal";
import SpinnerIcon from "~/assets/svg/spinner.svg?component";
import { useMainStore } from "@/store/index";
import { useLocaleStore } from "~/store/locales";
import { useModal } from "vue-final-modal";
import { useTranslation } from "i18next-vue";

const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const mainStore = useMainStore();
const localeStore = useLocaleStore();
const $config = nuxtApp.$config.public;

const formFields = reactive({
  name: "",
  email: "",
  phone: "",
  product_id: "",
  message: "",
  accept_terms: "",
});

const products = ref(null);
const testDrivePageContent = ref(null);
const policyData = ref(null);

const intercom = ref(
  typeof nuxtApp.$intercom !== "undefined" ? nuxtApp.$intercom : null
);
const selected = ref(null);
const subjects = ref(["general_enquiry", "marketing_and_sales"]);
let form = reactive(Object.assign({}, formFields));
const isSending = ref(false);
let errorsObj = reactive({});
const searchQuery = ref(null);
let options = reactive({
  placeholder: t("common.phone_number_placeholder"),
});
const link = ref(null);
const invisibleHcaptcha = ref(null);

// Getters
const fallbackCountry = mainStore.getCountry;
const defaultLocales = localeStore.getDefaultLocales;

// Initial data function

try {
  const productsData = await useAPI(
    "api/front/products?test-ride-inquiry=true"
  );
  if (productsData.data.error) {
    console.log(productsData.data.error, "error");
  } else {
    products.value = productsData.data._rawValue;
  }
} catch (e) {
  console.log(e, "error products");
}

try {
  const testRideData = await useAPI("api/front/pages/test-ride-inquiry");
  if (testRideData.data.error) {
    console.log(testRideData.data.error, "error");
  } else {
    testDrivePageContent.value = testRideData.data._rawValue.data;
  }
} catch (e) {
  console.log(e, "error testRideData");
}

try {
  const policyDataResponse = await useAPI("api/front/pages/test-ride-policy");
  if (policyDataResponse.data.error) {
    console.log(policyDataResponse.data.error, "error");
  } else {
    policyData.value = policyDataResponse.data._rawValue.data;
  }
} catch (e) {
  console.log(e, "error policyData");
}

onMounted(() => {
  if (link.value.firstElementChild && policyData.value) {
    link.value.firstElementChild.addEventListener("click", onOpenModal);
  }
});

onBeforeUnmount(() => {
  if (link.value && link.value.firstElementChild && policyData.value) {
    link.value.firstElementChild.removeEventListener("click", onOpenModal);
  }
});

const { open, close } = useModal({
  component: PrivacyPolicyTestDriveModal,
  attrs: {
    policyData: policyData.value,
  },
});
const onOpenModal = open;

function clearValidation(field) {
  switch (field) {
    case "name":
      errorsObj.name = null;
      break;
    case "email":
      errorsObj.email = null;
      break;
    case "phone":
      errorsObj.phone = null;
      break;
    case "product_id":
      errorsObj.product_id = null;
      break;
    case "message":
      errorsObj.message = null;
      break;
    case "accept_terms":
      errorsObj.accept_terms = null;
      break;
    case "selected":
      errorsObj.selected = null;
      break;

    default:
      break;
  }
}

function onVerify(token, ekey) {
  const defaultCountry = defaultLocales.find(
    (_v) => _v.slug === fallbackCountry
  );
  form.company_id = defaultCountry ? defaultCountry.id : "";
  form.product_id = selected.value ? selected.value.id : "";

  useAPI("/api/front/test-ride-inquiry", {
    method: "POST",
    body: { ...form, "h-captcha-response": token },
  }).then((response) => {
    if (response.error.value) {
      errorsObj = response.error.value.cause._data.errors;
      nuxtApp.$toast.error(response.error.value.cause._data.message);
      isSending.value = false;
    } else {
      form = Object.assign({}, formFields);
      selected.value = null;
      nuxtApp.$toast.success(t("common.test_ride_inquiry.thanks"));
      isSending.value = false;

      window._iub.cons_instructions.push([
        "submit",
        {
          writeOnLocalStorage: false,
          form: {
            selector: document.getElementById("test_ride_form"),
          },
          consent: {
            legal_notices: [
              {
                identifier: "privacy_policy",
              },
              {
                identifier: "cookie_policy",
              },
              {
                identifier: "term",
              },
            ],
          },
        },
        {
          success(response) {
            // console.log(response)
          },
          error(response) {
            console.warning(response);
          },
        },
      ]);
    }
  });
}

function onChangeCountry(event) {
  if (event) {
    form.country_code = event.id;
    form.country_name = event.name;
  }
}

function onExpire() {
  nuxtApp.$toast.error("Captcha expired");
  console.log("[vue-htcapcha] Expired token");
  isSending.value = false;
}

function onError() {
  console.log("[vue-htcapcha] Failed to load hcaptcha");
  isSending.value = false;
}

function onSubmit() {
  if (isSending.value) {
    return;
  }

  isSending.value = true;
  errorsObj = {};

  invisibleHcaptcha.value.execute();
}
</script>

<style lang="postcss">
@import "vue-tel-input/vue-tel-input.css";

.contact--checkbox {
  .contact__checkmark {
    height: 17px;
    width: 17px;
    top: 0.2rem;
    left: 0;
    border-radius: 5px;

    &::after {
      content: "";

      @apply absolute hidden;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.test-ride #phone {
  @apply w-full !h-[48px] !border-gray-300 font-myriad-pro !text-black !rounded-[10px];
}

.test-ride .vue-tel-input input:focus {
  @apply focus:ring-primary-500 focus:border-primary-500;
}

.test-ride .vue-tel-input:focus-within {
  @apply shadow-none;
}

.terms a {
  @apply !text-black;
}

.terms a#privacy-policy {
  @apply text-primary-500;
}

.test-drive-info p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
ul {
  @apply my-4;
}
.test-drive-info h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold;
}
.test-drive-info ul {
  @apply list-disc pl-10;
}
.test-ride .terms a {
  @apply !text-black;
  &:hover {
    @apply !text-primary;
  }
}
</style>
