<template>
  <section>
    <HeaderSection :title="title" />
    <div class="container y-6">
      <!-- eslint-disable-next-line-->
      <div class="py-5 static-page" v-html="content" />
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
const title = ref(t("common.disclaimer"));
const content = ref(null);
const $config = useRuntimeConfig().public;

try {
  const { data } = await useAPI("api/front/pages/disclaimer");

  content.value = data._rawValue.data.content;
} catch (e) {
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}
useNuxtApp().$seoModule({
  page_title: title.value,
  meta_name: title.value,
  meta_description: title.value,
  og_name: title.value,
  og_description: title.value,
  model_id: "disclaimer",
  model_type: "FrontendPage",
});
</script>
