<template>
  <component v-if="dynamicComponent" :is="dynamicComponent" />
</template>
<script setup>
import { forIn } from "lodash-es";
import { useMainStore } from "~/store";
import { useTranslation } from "i18next-vue";

const PagesContactUs = resolveComponent("LazyPagesContactUs");
const PagesAboutUs = resolveComponent("PagesAboutUs");
const PagesBag = resolveComponent("PagesBag");
const PagesBecomeADealer = resolveComponent("PagesBecomeADealer");
const PagesBecomeADistributor = resolveComponent("PagesBecomeADistributor");
const PagesCookiePolicy = resolveComponent("PagesCookiePolicy");
const PagesDisclaimer = resolveComponent("PagesDisclaimer");
const PagesHowToBuy = resolveComponent("PagesHowToBuy");
const PagesLogin = resolveComponent("PagesLogin");
const PagesManuals = resolveComponent("PagesManuals");
const PagesPrivacyPolicy = resolveComponent("PagesPrivacyPolicy");
const PagesRecovery = resolveComponent("PagesRecovery");
const PagesRegister = resolveComponent("PagesRegister");
const PagesShippingPolicy = resolveComponent("PagesShippingPolicy");
const PagesStores = resolveComponent("PagesStores");
const PagesSubscriberVerify = resolveComponent("PagesSubscriberVerify");
const PagesTerms = resolveComponent("PagesTerms");
const PagesTestDrive = resolveComponent("PagesTestDrive");
const PagesUnsubscribe = resolveComponent("PagesUnsubscribe");
const PagesWarranty = resolveComponent("PagesWarranty");
const PagesWorkWithUs = resolveComponent("PagesWorkWithUs");
const PagesNews = resolveComponent("PagesNews");
const PagesTheMuseum = resolveComponent("PagesTheMuseum");

const route = useRoute();
const dynamicComponent = shallowRef();
const store = useMainStore();
const nuxtApp = useNuxtApp();
const { t } = useTranslation();

const findComponent = () => {
  let page = "";
  for (const key in store.pageLinks) {
    if (store.pageLinks[key] === route.params.page) {
      page = key;
    }
  }
  let seoData = null;
  switch (page) {
    case "contact-us":
      dynamicComponent.value = PagesContactUs;
      seoData = {
        page_title: t("common.contact_us"),
        meta_name: t("common.contact_us"),
        meta_description: t("common.contact_us"),
        og_name: t("common.contact_us"),
        og_description: t("common.contact_us"),
        model_id: "contact-us",
        model_type: "FrontendPage",
      };
      break;
    case "about-us":
      dynamicComponent.value = PagesAboutUs;
      seoData = {
        page_title: t("common.about_us"),
        meta_name: t("common.about_us"),
        meta_description: t("common.about_us_main_title"),
        og_name: t("common.about_us"),
        og_description: t("common.about_us_main_title"),
        model_id: "about-us",
        model_type: "FrontendPage",
      };
      break;
    case "bag":
      dynamicComponent.value = PagesBag;
      break;
    case "become-a-dealer":
      dynamicComponent.value = PagesBecomeADealer;
      seoData = {
        page_title: t("common.become_a_dealer"),
        meta_name: t("common.become_a_dealer"),
        meta_description: t("common.become_a_dealer"),
        og_name: t("common.become_a_dealer"),
        og_description: t("common.become_a_dealer"),
        model_id: "become-a-dealer",
        model_type: "FrontendPage",
      };
      break;
    case "become-a-distributor":
      dynamicComponent.value = PagesBecomeADistributor;
      seoData = {
        page_title: t("common.become_a_distributor"),
        meta_name: t("common.become_a_distributor"),
        meta_description: t("common.become_a_distributor"),
        og_name: t("common.become_a_distributor"),
        og_description: t("common.become_a_distributor"),
        model_id: "become-a-distributor",
        model_type: "FrontendPage",
      };
      break;
    case "cookie-policy":
      dynamicComponent.value = PagesCookiePolicy; // dynamic
      break;
    case "disclaimer":
      dynamicComponent.value = PagesDisclaimer; //d
      break;
    case "how-to-buy":
      dynamicComponent.value = PagesHowToBuy; //d
      break;
    case "login":
      dynamicComponent.value = PagesLogin; //n
      break;
    case "manuals":
      dynamicComponent.value = PagesManuals;
      seoData = {
        page_title: t("common.manuals"),
        meta_name: t("common.manuals"),
        meta_description: t("common.manuals"),
        og_name: t("common.manuals"),
        og_description: t("common.manuals"),
        model_id: "manuals",
        model_type: "FrontendPage",
      };
      break;
    case "privacy-policy":
      dynamicComponent.value = PagesPrivacyPolicy; //d
      break;
    case "recovery":
      dynamicComponent.value = PagesRecovery; //d
      break;
    case "register":
      dynamicComponent.value = PagesRegister; //n
      break;
    case "shipping-policy":
      dynamicComponent.value = PagesShippingPolicy; //d
      break;
    case "stores":
      dynamicComponent.value = PagesStores;
      seoData = {
        page_title: t("common.stores_and_inventories"),
        meta_name: t("common.stores_and_inventories"),
        meta_description: t("common.stores_and_inventories"),
        og_name: t("common.stores_and_inventories"),
        og_description: t("common.stores_and_inventories"),
        model_id: "stores",
        model_type: "FrontendPage",
      };
      break;
    case "subscriber-verify":
      dynamicComponent.value = PagesSubscriberVerify;
      seoData = {
        page_title: t("common.newsletter_thanks"),
        meta_name: t("common.newsletter_thanks"),
        meta_description: t("common.newsletter_thanks"),
        og_name: t("common.newsletter_thanks"),
        og_description: t("common.newsletter_thanks"),
        model_id: "subscriber-verify",
        model_type: "FrontendPage",
      };
      break;
    case "terms":
      dynamicComponent.value = PagesTerms; //d
      break;
    case "work-with-us":
      dynamicComponent.value = PagesWorkWithUs;
      seoData = {
        page_title: t("common.work_with_us"),
        meta_name: t("common.work_with_us"),
        meta_description: t("common.work_with_us"),
        og_name: t("common.work_with_us"),
        og_description: t("common.work_with_us"),
        model_id: "work-with-us",
        model_type: "FrontendPage",
      };
      break;
    case "unsubscribe":
      dynamicComponent.value = PagesUnsubscribe;
      seoData = {
        page_title: t("common.unsubscribe"),
        meta_name: t("common.unsubscribe"),
        meta_description: t("common.unsubscribe"),
        og_name: t("common.unsubscribe"),
        og_description: t("common.unsubscribe"),
        model_id: "unsubscribe",
        model_type: "FrontendPage",
      };
      break;
    case "warranty":
      dynamicComponent.value = PagesWarranty; //d
      break;
    case "test-drive":
      dynamicComponent.value = PagesTestDrive;

      seoData = {
        page_title: t("common.test_ride_inquiry.title"),
        meta_name: t("common.test_ride_inquiry.title"),
        meta_description: t("common.test_ride_inquiry.title"),
        og_name: t("common.test_ride_inquiry.title"),
        og_description: t("common.test_ride_inquiry.title"),
        model_id: "test-drive",
        model_type: "FrontendPage",
      };
      break;
    case "news":
      dynamicComponent.value = PagesNews;
      seoData = {
        page_title: t("common.news"),
        meta_name: t("common.news"),
        meta_description: t("common.news"),
        og_name: t("common.news"),
        og_description: t("common.news"),
        model_id: "news",
        model_type: "FrontendPage",
      };
      break;
    case "the-museum":
      dynamicComponent.value = PagesTheMuseum;
      seoData = {
        page_title: t("common.museum"),
        meta_name: t("common.museum"),
        meta_description: t("common.museum"),
        og_name: t("common.museum"),
        og_description: t("common.museum"),
        model_id: "museum",
        model_type: "FrontendPage",
      };
      break;
    default:
      throw createError({
        statusCode: 404,
        statusMessage: "Page Not Found",
      });
      break;
  }
  if (seoData) {
    nuxtApp.$seoModule(seoData);
  }
};

findComponent();
</script>
