<template>
  <section>
    <HeaderSection :title="title" />
    <div class="container py-6">
      <!-- eslint-disable-next-line-->
      <div v-html="content" class="static-page" />
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
const title = ref("common.how_to_buy");
let content = ref(null);
const $config = useRuntimeConfig().public;

howToBuy();

async function howToBuy() {
  try {
    const { data } = await useAPI("api/front/pages/how-to-buy");
    if (data._rawValue.data) {
      content.value = data._rawValue.data.content;
      title.value = data._rawValue.data.title;
    }
  } catch (error) {
    console.log(error, "error");
  }
}

useNuxtApp().$seoModule({
  page_title: title.value,
  meta_name: title.value,
  meta_description: title.value,
  og_name: title.value,
  og_description: title.value,
  model_id: "how-to-buy",
  model_type: "FrontendPage",
});
</script>
