import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#__clip0_4549_6845)\"><path d=\"M3.33325 5.83366C3.33325 5.39163 3.50885 4.96771 3.82141 4.65515C4.13397 4.34259 4.55789 4.16699 4.99992 4.16699H14.9999C15.4419 4.16699 15.8659 4.34259 16.1784 4.65515C16.491 4.96771 16.6666 5.39163 16.6666 5.83366V15.8337C16.6666 16.2757 16.491 16.6996 16.1784 17.0122C15.8659 17.3247 15.4419 17.5003 14.9999 17.5003H4.99992C4.55789 17.5003 4.13397 17.3247 3.82141 17.0122C3.50885 16.6996 3.33325 16.2757 3.33325 15.8337V5.83366Z\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M13.3333 2.5V5.83333\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M6.66675 2.5V5.83333\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M3.33325 9.16699H16.6666\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M9.16675 13.3333C9.16675 13.5543 9.25455 13.7663 9.41083 13.9226C9.56711 14.0789 9.77907 14.1667 10.0001 14.1667C10.2211 14.1667 10.4331 14.0789 10.5893 13.9226C10.7456 13.7663 10.8334 13.5543 10.8334 13.3333C10.8334 13.1123 10.7456 12.9004 10.5893 12.7441C10.4331 12.5878 10.2211 12.5 10.0001 12.5C9.77907 12.5 9.56711 12.5878 9.41083 12.7441C9.25455 12.9004 9.16675 13.1123 9.16675 13.3333Z\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g><defs><clipPath id=\"__clip0_4549_6845\"><rect width=\"20\" height=\"20\" fill=\"white\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }