<template>
  <div>
    <!-- AboutUs Hero section -->
    <AboutHeroSection />
    <AboutNavSection />

    <!-- About Us Pesaro Italy BenellISection -->
    <section class="items-stretch lg:flex">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="overflow-hidden md:order-1 md:h-[540px] lg:h-[578px] xl:h-[666px] 3xl:h-[680px] 4xl:h-[710px] xl:flex md:w-1/2"
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-offset="50"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-benelli.png`"
            image-class="self-center object-cover object-right w-full h-full mx-auto xyz-nested"
            alt="About Us Benelli"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start px-4 md:px-[30px] lg:px-0 overflow-hidden md:h-[540px] lg:h-[578px] xl:h-[666px] 3xl:h-[680px] 4xl:h-[710px] md:order-2 xl:py-8 md:w-1/2"
          data-aos="fade-left"
          data-aos-delay="10"
        >
          <div
            class="py-[30px] xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:ml-8 xl:mx-14 2xl:mx-[90px] 2xl:my-32 w-full lg:w-[448px] xl:w-[540px]"
          >
            <!-- w-[299px]  md:w-[270px] lg:w-[340px] xl:w-[471px] xl:w-[510px] -->
            <h1
              class="text-[20px] xl:text-[33px] uppercase lg:text-[26px] lg:leading-[48px] italic font-myriad-pro font-semibold text-black xl:tracking-normal md:mt-0 leading-[30px] xl:leading-[48px] text-start"
              v-html="
                $t('common.about_us_section_1_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <!-- xl:w-[525px]  -->
            <div class="mt-[10px]">
              <p
                class="text-[15px] md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] xl:text-[20px] leading-[24px] font-normal xl:leading-[36px] font-myriad-pro"
                v-html="
                  $t('common.about_us_section_1_description', {
                    benelli_brand: 'Benelli',
                    interpolation: { prefix: '[:', suffix: ']' },
                  })
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- BK Six brother -->
    <section class="relative">
      <ImgLazyLoader
        :src="`/about-us${folderName}/about-us-bk.png`"
        image-class="order-1 md:h-[500px] lg:h-[578px] xl:h-[818px] 3xl:h-[830px] object-cover 4xl:h-[900px] w-full bg-secondary"
        alt="BK"
      />

      <div
        class="xl:px-4 pb-[30px] py-6 md:py-10 lg:py-[50px] xl:py-[60px] order-2 w-full bg-secondary"
      >
        <div
          class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mx-auto mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
        />
        <div
          class="w-full lg:w-[784px] xl:w-[1214px] px-4 mt-[24px] md:mx-auto md:max-w-7xl 2 md:px-16"
        >
          <p
            class="text-[15px] leading-6 md:text-sm md:leading-[22px] lg:text-[16px] lg:leading-[26px] text-center text-white xl:text-[20px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
            v-html="
              $t('common.about_us_section_2', {
                benelli_brand: 'Benelli',
                interpolation: { prefix: '[:', suffix: ']' },
              })
            "
          />
        </div>
      </div>
    </section>

    <!-- Early Years (1911-1939) -->
    <section class="items-stretch lg:flex" id="about_1911_1939">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="overflow-hidden md:order-2 xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
          data-aos-offset="30"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-benelli-history.png`"
            image-class="self-center object-cover object-right w-full h-full px-4 pt-4 md:p-0 xyz-nested"
            alt="Benelli History"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start px-4 md:px-[30px] lg:px-0 overflow-hidden md:order-1 md:justify-end md:w-1/2"
          data-aos="fade-left"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:mr-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div>
              <ImgLazyLoader
                src="/about-us/benelli-lion-logo.png"
                class="md:w-[60px] md:h-[60px] lg:w-[74px] lg:h-[74px] xl:h-[98px] xl:w-[98px] w-[86px] h-[86px] mb-[20px] xyz-nested"
                alt="Benelli Lion Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
            </div>
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] font-myriad-pro font-bold text-[#404040] xl:tracking-normal mt-[16px] md:mt-0 leading-[30px] xl:leading-[36px] text-start"
              v-html="
                $t('common.about_us_1911_1939_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] xl:text-[20px] lg:mt-[10px] text-[#020202] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_1911_1939_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- World War II and Recovery (1940-1959) -->
    <section class="items-stretch lg:flex" id="about_1940_1959">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="overflow-hidden md:order-1 xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-benelli-500cc.png`"
            image-class="self-center object-cover object-right w-full h-full px-4 pt-4 md:p-0 xyz-nested"
            class="w-full h-full"
            alt="Benelli"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start px-4 md:px-[30px] lg:px-0 overflow-hidden md:order-2 xl:py-14 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:ml-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div>
              <ImgLazyLoader
                src="/about-us/benelli-classic-logo.png"
                class="md:w-[113px] md:h-[38.504px] lg:w-[135px] lg:h-[46px] xl:h-[66px] xl:w-[195px] w-[108px] h-[39px] mb-[20px] xyz-nested"
                alt="Benelli Classic Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
            </div>
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-[#404040] xl:tracking-normal mt-[16px] md:mt-0 xl:leading-[36px] text-start md:w-[250px] w-full lg:w-full"
              v-html="
                $t('common.about_us_1940_1959_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] xl:text-[20px] mt-[10px] font-lightext-[#020202] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_1940_1959_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Expansion and Innovation (1960-1980) -->
    <section class="items-stretch lg:flex" id="about_1960_1980">
      <div class="flex flex-col w-full overflow-hidden md:flex-row">
        <div
          class="order-1 overflow-hidden md:order-2 xl:flex md:w-1/2 md:h-[580px] lg:h-[540px] xl:h-[640px] 2xl:h-[700px] 3xl:h-[750px]"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[640px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-50cc-watercooled.png`"
            image-class="self-center object-cover object-right w-full h-full px-4 pt-4 mx-auto md:p-0 xyz-nested"
            alt="50cc Watercooled"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start order-2 px-4 md:px-[30px] lg:px-0 overflow-hidden md:order-1 md:justify-end xl:py-8 md:w-1/2 md:h-[580px] lg:h-[540px] xl:h-[640px] 2xl:h-[700px] 3xl:h-[750px]"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[640px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:mr-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div
              class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 skew-x-[-50deg] flex justify-start items-start text-start mb-[16px] lg:mb-[24px] border-primary"
            />
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-[#404040] xl:tracking-normal mt-[16px] md:mt-0 xl:leading-[36px] text-start md:w-[250px] w-full lg:w-full"
              v-html="
                $t('common.about_us_1960_1980_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] text-[#404040] mt-[10px] xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_1960_1980_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Challenges and Resurgence (1971-1988) -->
    <section class="items-stretch lg:flex" id="about_1971_1988">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="order-1 overflow-hidden xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-six-cylinder-750cc-benelli.png`"
            image-class="self-center object-right w-full h-full px-4 pt-4 mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="50cc Watercooled Benelli"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px] order-2 px-4 md:px-[30px] lg:px-0 overflow-hidden xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:ml-8 xl:mx-14 2xl:mx-[90px]"
          >
            <ImgLazyLoader
              src="/about-us/about_us_1971_1988.png"
              class="xl:h-[98px] xl:w-[98px] w-[86px] h-[86px] mb-[20px] xyz-nested md:w-[60px] md:h-[60px]"
              alt="Benelli Classic Logo"
              xyz="fade small delay-5"
              loading="lazy"
            />
            <div
              class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 skew-x-[-50deg] flex justify-start items-start text-start mb-[16px] lg:mb-[24px] border-primary"
            />
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-[#404040] xl:tracking-normal mt-[16px] md:mt-0 xl:leading-[36px] text-start w-[250px] lg:w-full"
              v-html="
                $t('common.about_us_1971_1988_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] mt-[10px] text-[#404040] xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_1971_1988_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- The New Era of TORNADO’s -->
    <section class="items-stretch lg:flex" id="about_2000s">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="order-2 overflow-hidden xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-tornado-benelli.png`"
            image-class="self-center object-right w-full h-full px-4 pt-4 mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="The New Era of TORNADO’s"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start order-1 px-4 overflow-hidden md:px-0 md:justify-end xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 py-[41px] xl:pb-0 xl:py-4 lg:mr-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div
              class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 skew-x-[-50deg] flex justify-start items-start text-start mb-[16px] lg:mb-[24px] border-primary"
            />
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-[#404040] xl:tracking-normal mt-[16px] md:mt-0 md:leading-[3rem] xl:leading-[36px] text-start"
              v-html="
                $t('common.about_us_2000s_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] mt-[10px] text-[#404040] xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_2000s_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Modern Era and Global Expansion (2000-2024) -->
    <section id="about_2000_2024" class="items-stretch lg:flex">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="order-1 overflow-hidden xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-global-expansion.png`"
            image-class="self-center object-right sm:h-[580px] md:h-full w-full h-full  px-4 pt-4 mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="Global Expansion"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
        </div>
        <div
          class="flex items-center justify-start order-2 px-4 md:px-[30px] lg:px-0 overflow-hidden xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:ml-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div>
              <ImgLazyLoader
                src="/about-us/benelli-logo.png"
                class="md:w-[60px] md:h-[60px] lg:h-[74px] lg:w-[74px] xl:h-[98px] xl:w-[98px] w-[86px] h-[86px] mb-[20px] xyz-nested"
                alt="Benelli Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
            </div>
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-[#404040] xl:tracking-normal mt-[16px] md:mt-0 xl:leading-[36px] text-start w-[350px] xl:w-full"
              v-html="
                $t('common.about_us_2000_2024_title', {
                  benelli_brand: 'Benelli',
                  keeway_brand: 'Keeway',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] mt-[10px] text-[#404040] xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_2000_2024_description', {
                  benelli_brand: 'Benelli',
                  keeway_brand: 'Keeway',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Centrostile benelli -->
    <section class="items-stretch lg:flex bg-[#000000]" id="about_2015">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="order-2 overflow-hidden xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-benelli-establish.png`"
            image-class="self-center object-right w-full h-full px-4 pt-4 mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="Benelli Establish"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
          <div
            class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full"
          >
            <PlayIcon
              class="cursor-pointer w-16 h-16 lg:w-[100px] border-2 lg:h-[100px] text-white rounded-full"
              style="
                backdrop-filter: blur(17.5px);
                border: 1px rgba(255, 255, 255, 0.2);
                background: rgba(0, 0, 0, 0.4);
              "
              @click.prevent="openLightbox(20, true)"
            />
          </div>
        </div>
        <div
          class="flex items-center justify-start order-1 px-4 overflow-hidden md:justify-end xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:mr-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div>
              <ImgLazyLoader
                src="/about-us/benelli-centro-site-logo.png"
                class="md:w-[226px] md:h-[33.718px] lg:h-[37px] lg:w-[248px] xl:h-[54px] xl:w-[363px] w-[258px] h-[42px] mb-[20px] xyz-nested"
                alt="Centro Site Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
            </div>

            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] mt-[10px] text-white xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_2015_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Leoncino new era change-->
    <section class="items-stretch lg:flex bg-[#000000]" id="about_leoncino">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="order-1 overflow-hidden xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-change-of-era.png`"
            image-class="self-center object-right sm:h-[580px] md:h-full w-full h-full  px-4 pt-4 mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="Change Of Era"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
          <div
            class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full"
          >
            <PlayIcon
              class="cursor-pointer w-16 h-16 lg:w-[100px] lg:h-[100px] text-white rounded-full"
              style="
                backdrop-filter: blur(17.5px);
                border: 1px rgba(255, 255, 255, 0.2);
                background: rgba(255, 255, 255, 0.08);
              "
              @click.prevent="openLightbox(21, true)"
            />
          </div>
        </div>
        <div
          class="flex items-center justify-start order-2 px-4 md:px-[30px] lg:px-0 overflow-hidden xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:ml-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div>
              <ImgLazyLoader
                src="/about-us/benelli-leoncino-logo.png"
                class="w-[209px] h-[42px] xl:w-[300px] xl:h-[41px] md:w-[184px] md:h-[25.17px] lg:w-[212px] lg:h-[29px] mb-[20px] xyz-nested"
                alt="Leoncino Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
            </div>
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-white xl:tracking-normal mt-[16px] md:mt-0 xl:leading-[36px] text-start"
              v-html="
                $t('common.about_us_leoncino_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] mt-[10px] text-white xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_leoncino_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Gallery Section -->
    <section class="bg-black px-4 md:py-[40px] py-[40px]">
      <div
        class="grid grid-cols-2 gap-[5px] xl:container sm:grid-cols-4 lg:grid-cols-5"
      >
        <div
          v-for="(galleryItem, index) in gallery"
          :key="index"
          class=""
          @click.prevent="openLightbox(index, false)"
        >
          <ImgLazyLoader
            :src="galleryItem"
            alt="Gallery Image"
            class="cursor-pointer"
          />
        </div>
      </div>
    </section>

    <!-- TRK Erasection Bike -->
    <section class="items-stretch lg:flex" id="about_trk">
      <div class="w-full overflow-hidden md:flex md:flex-row">
        <div
          class="order-1 overflow-hidden xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-trk.png`"
            image-class="self-center object-right w-full h-full px-4 pt-4 mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="TRK"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
          <div
            class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full"
          >
            <PlayIcon
              class="cursor-pointer w-16 h-16 lg:w-[100px] lg:h-[100px] text-white rounded-full"
              style="
                backdrop-filter: blur(17.5px);
                border: 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(0, 0, 0, 0.4);
              "
              @click.prevent="openLightbox(22, true)"
            />
          </div>
        </div>
        <div
          class="flex items-center bg-white md:bg-[#000000] justify-start order-2 px-4 md:px-[30px] lg:px-0 overflow-hidden xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] pb-[40px] pt-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 lg:ml-8 xl:mx-14 2xl:mx-[90px]"
          >
            <div>
              <ImgLazyLoader
                src="/about-us/benelli-trk-logo.png"
                class="md:w-[143px] md:h-[22px] hidden sm:block h-[37.302px] lg:w-[195px] lg:h-[30px] xl:h-[39px] xl:w-[241px] 2xl:w-[241px] mb-[29px] xyz-nested"
                alt="TRK Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <ImgLazyLoader
                src="/about-us/mobile-trk-logo.png"
                class="block sm:hidden w-[173px] h-[42px] md:h-[37.302px] mb-[20px] xyz-nested"
                alt="Mobile TRK Logo"
                xyz="fade small delay-5"
                loading="lazy"
              />
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
            </div>
            <h1
              class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-black md:text-white xl:tracking-normal mt-[16px] md:mt-0 xl:leading-[36px] text-start"
              v-html="
                $t('common.about_us_trk_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></h1>
            <p
              class="text-[15px] leading-6 md:text-base md:leading-[24px] lg:text-[16px] lg:leading-[26px] mt-[10px] text-black md:text-white xl:text-[19px] xl:leading-[36px] font-myriad-pro font-normal xl:leading-[32px]"
              v-html="
                $t('common.about_us_trk_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Benelli Just Beginning-->
    <section
      v-if="store.country != 'it'"
      class="items-stretch lg:flex"
      id="about_tornado"
    >
      <div
        class="flex flex-col-reverse items-center w-full overflow-hidden bg-black md:flex-row"
      >
        <div
          class="order-1 overflow-hidden md:order-2 xl:flex md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <ImgLazyLoader
            :src="`/about-us${folderName}/about-us-beginning.png`"
            image-class="self-center object-right w-full h-full px-4 pb-[60px] mx-auto md:p-0 xl:object-cover xyz-nested"
            alt="Beginning"
            class="w-full h-full"
            xyz="fade small delay-5"
            loading="lazy"
          />
          <div
            class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full"
          >
            <PlayIcon
              class="cursor-pointer w-16 h-16 lg:w-[100px] lg:h-[100px] text-white rounded-full"
              style="
                backdrop-filter: blur(17.5px);
                border: 1px rgba(255, 255, 255, 0.2);
                background-color: rgba(255, 255, 255, 0.06);
              "
              @click.prevent="openLightbox(23, true)"
            />
          </div>
        </div>

        <div
          class="flex items-center justify-end order-2 px-4 overflow-hidden md:order-1 xl:py-8 md:w-1/2"
          :class="
            countryWiseClass(
              'md:h-[580px] lg:h-[540px] xl:h-[670px] 2xl:h-[700px] 3xl:h-[750px]'
            )
          "
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div
            class="w-full lg:w-[448px] xl:w-[525px] md:flex-col flex-col-reverse items-center md:items-start md:justify-start justify-center flex py-6 xl:pt-0 md:pb-[10px] lg:pb-[30px] xl:pb-0 xl:py-4 md:pl-4 lg:mr-8 xl:mx-14 2xl:mx-[90px]"
          >
            <ImgLazyLoader
              src="/about-us/benelli-begining-logo.png"
              class="md:mb-[34px] mt-[24px] md:mt-0 xyz-nested w-[132px] h-[46px] xl:h-[59.535px] xl:w-[171.802px] lg:h-[44px] lg:w-[118px] md:h-[38px] md:w-[132px]"
              alt="Beginning Logo"
              xyz="fade small delay-5"
              loading="lazy"
            />
            <div
              class="flex flex-col items-center justify-center md:items-start md:justify-start"
            >
              <div
                class="border-b-4 xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 mb-[16px] md:mb-[16px] lg:mb-[24px] skew-x-[-50deg] border-primary"
              />
              <h1
                class="text-[20px] xl:text-[28px] lg:text-[22px] leading-[30px] font-myriad-pro font-bold text-white xl:tracking-normal md:mt-[16px] xl:leading-[36px] text-start"
                v-html="
                  $t('common.about_us_just_beginning', {
                    benelli_brand: 'Benelli',
                    interpolation: { prefix: '[:', suffix: ']' },
                  })
                "
              ></h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About Us Gallery Modal -->
    <div class="aboutus-gallary">
      <Lightbox
        :items="videoGallery"
        :initialIndex="lightboxIndex"
        @update:visible="(e) => (lightboxIndex = e)"
      />
    </div>
  </div>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import { useMainStore } from "~/store";
import PlayIcon from "~/assets/svg/heroicons/play.svg?component";

const { t } = useTranslation();
const $config = useRuntimeConfig().public;
const store = useMainStore();

let controls = ref(false);
const lightboxIndex = ref(null);
const lightboxImages = ref([]);
const isVideo = ref(false);
const isMore = ref(false);
const controller = ref(null);
const media = ref([]);
const mediaLightBox = ref([]);
const folderName = ref("/screen-1440");

const gallery = computed(() => {
  return [
    `/about-us/gallery/1.png`,
    `/about-us/gallery/3.png`,
    `/about-us/gallery/18.png`,
    `/about-us/gallery/4.png`,
    `/about-us/gallery/5.png`,
    `/about-us/gallery/6.png`,
    `/about-us/gallery/7.png`,
    `/about-us/gallery/8.png`,
    `/about-us/gallery/9.png`,
    `/about-us/gallery/10.png`,
    `/about-us/gallery/11.png`,
    `/about-us/gallery/12.png`,
    `/about-us/gallery/13.png`,
    `/about-us/gallery/14.png`,
    `/about-us/gallery/15.png`,
    `/about-us/gallery/16.png`,
    `/about-us/gallery/17.png`,
    `/about-us/gallery/2.png`,
    `/about-us/gallery/19.png`,
    `/about-us/gallery/20.png`,
  ];
});
const videoGallery = computed(() => {
  const isTornado =
    store.country != "it"
      ? [
          {
            src: `${$config.CDN_DOMAIN}/gallery/TNT2024.mp4`,
            poster: `/about-us${folderName.value}/about-us-beginning.png`,
            type: "video",
          },
        ]
      : [];

  let galleryBox = gallery.value.map((_v) => {
    return {
      src: _v,
      poster: _v,
      type: "image",
    };
  });

  return [
    ...galleryBox,
    {
      src: `${$config.CDN_DOMAIN}/gallery/LEO8002024.mp4`,
      poster: `/about-us${folderName.value}/about-us-benelli-establish.png`,
      type: "video",
    },
    {
      src: `${$config.CDN_DOMAIN}/gallery/Leo2024.mp4`,
      poster: `/about-us${folderName.value}/about-us-change-of-era.png`,
      type: "video",
    },
    {
      src: `${$config.CDN_DOMAIN}/gallery/TRK-702X-2023.mp4`,
      poster: `/about-us${folderName.value}/about-us-trk.png`,
      type: "video",
    },
    ...isTornado,
  ];
});
if (process.browser) {
  if (window.innerWidth >= 768 && window.innerWidth < 1024) {
    folderName.value = "/screen-768";
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    folderName.value = "/screen-1024";
  } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
    folderName.value = "/screen-1440";
  } else if (window.innerWidth >= 1920 && window.innerWidth < 2500) {
    folderName.value = "/screen-1920";
  } else if (window.innerWidth >= 2500) {
    folderName.value = "/screen-2500";
  } else {
    folderName.value = "/screen-375";
  }

  window.addEventListener("resize", onResize);
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

function openLightbox(index, isVideo) {
  lightboxIndex.value = index;
}

function countryWiseClass(defaultClass = "") {
  return store.country == "it" ? "h-auto" : defaultClass;
}
function showVideoControls() {
  controls.value = true;
}

function hideVideoControls() {
  controls.value = false;
}

function onResize() {
  if (window.innerWidth >= 768 && window.innerWidth < 1024) {
    folderName.value = "/screen-768";
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    folderName.value = "/screen-1024";
  } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
    folderName.value = "/screen-1440";
  } else if (window.innerWidth >= 1920 && window.innerWidth < 2500) {
    folderName.value = "/screen-1920";
  } else if (window.innerWidth >= 2500) {
    folderName.value = "/screen-2500";
  } else {
    folderName.value = "/screen-375";
  }
}
</script>

<style lang="postcss">
.about-us__history {
  background-repeat: no-repeat;
  background-position: bottom center;
  @apply md:bg-cover;

  p {
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
}
b {
  font-weight: 800;
}
.cool-lightbox {
  @apply !z-[99999999999];
}
.vue-lb-content {
  @apply max-w-none mb-0;
}
.vue-lb-header {
  @apply absolute z-10 right-2;
}
.cool-lightbox .cool-lightbox__iframe video {
  @apply !h-[95vh] !w-[97vw] object-cover rounded-[5px];
}
.cool-lightbox-toolbar__btn {
  @apply !fixed right-[1.5%] !top-[2.5%] z-[99999999];
}
.cool-lightbox-button {
  @apply !p-0  !w-[50px] !h-[50px];
}
.cool-lightbox .cool-lightbox-button.cool-lightbox-button--prev {
  @apply !left-[2.5%];
}
.cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
  @apply !right-[2.5%];
}
.cool-lightbox__slide__img {
  @apply !h-[95vh] !w-[97vw];
}
.cool-lightbox__slide__img img {
  @apply !h-[95vh] !w-auto !object-contain;
}
.cool-lightbox-toolbar .cool-lightbox-toolbar__btn {
  @apply !bg-transparent;
}
.cool-lightbox .cool-lightbox__slide img {
  @apply !shadow-none;
}
.controls {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 2px 2px;
  backdrop-filter: blur(12.5px);
  @apply w-[97vw] gap-2 lg:gap-6 px-2 md:px-5 h-10 left-[1.47%]  right-[1.5%] justify-between items-center fixed bottom-[2.5%] z-[999999999999];
}
.controls[data-state="hidden"] {
  display: none;
}

.controls[data-state="visible"] {
  display: block;
}
.controls > * {
  @apply block h-5;
}

.controls > *:first-child {
  margin-left: 0;
}
.controls .progress {
  @apply cursor-pointer w-full rounded-full h-2;
}
.controls button {
  border: none;
  cursor: pointer;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  @apply !min-w-[20px] h-5;
}
.controls button:hover,
.controls button:focus {
  opacity: 0.5;
}
#remaining-time {
  min-width: 47px;
}
.controls progress {
  /* -moz-border-radius: 100%; */
  /* -webkit-border-radius: 100%; */
  @apply rounded-full overflow-hidden text-[#0095dd] border-none w-full h-full block relative;
}
.controls progress[data-state="fake"] {
  background: #e6e6e6;
  height: 65%;
}
.controls progress span {
  width: 0%;
  height: 100%;
  display: inline-block;
  background-color: #2a84cd;
}
.controls #progress-dot {
  width: 20px;
  height: 20px;
  left: -10px;
  position: absolute;
  top: -6px;
  z-index: 9999999999999;
  background: white;
  border-radius: 100%;
}
.controls progress::-moz-progress-bar {
  background-color: #0095dd;
}

.controls progress::-webkit-progress-value {
  background-color: #0095dd;
}
figure[data-fullscreen="true"] video::-webkit-media-controls {
  display: none !important;
}
figure[data-fullscreen="true"] .controls {
  z-index: 2147483647;
}
</style>
