import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "14",
  viewBox: "0 0 16 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M1.25 3.25C1.25 2.85218 1.40804 2.47064 1.68934 2.18934C1.97064 1.90804 2.35218 1.75 2.75 1.75H13.25C13.6478 1.75 14.0294 1.90804 14.3107 2.18934C14.592 2.47064 14.75 2.85218 14.75 3.25V10.75C14.75 11.1478 14.592 11.5294 14.3107 11.8107C14.0294 12.092 13.6478 12.25 13.25 12.25H2.75C2.35218 12.25 1.97064 12.092 1.68934 11.8107C1.40804 11.5294 1.25 11.1478 1.25 10.75V3.25Z",
      stroke: "white",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      opacity: "0.3"
    }, null, -1)
  ])))
}
export default { render: render }