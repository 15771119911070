import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"20\" cy=\"20\" r=\"20\" fill=\"white\"></circle><g clip-path=\"url(#__clip0_7118_18834)\"><path d=\"M25.5 14.5L14.5 25.5\" stroke=\"#747474\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M14.5 14.5L25.5 25.5\" stroke=\"#747474\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g><defs><clipPath id=\"__clip0_7118_18834\"><rect width=\"22\" height=\"22\" fill=\"white\" transform=\"translate(9 9)\"></rect></clipPath></defs>", 3)
  ])))
}
export default { render: render }