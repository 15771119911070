<template>
  <section>
    <nav
      :class="scrolled >= 500 ? 'fixed top-0 inset-x-0 ' : 'hidden'"
      class="top-[64px] lg:top-[88px] cos about__subnav shadow-lg bg-white text-md xl:text-xl uppercase lg:px-6 z-[9002] animate-fade"
    >
      <client-only>
        <ScrollActive
          class="container overflow-x-auto whitespace-nowrap no-scrollbar"
          :scroll-top-margin="116"
          :modify-url="false"
        >
          <!-- about name -->
          <template #default="{ onClickActive }">
            <div
              class="flex flex-col justify-between py-4 md:flex-row lg:border-t lg:border-dark-gray-200"
              v-if="store.country == 'it'"
            >
              <div
                class="flex flex-row gap-8 pr-5 leading-7 flex-nowrap subsections lg:pr-0"
              >
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1911_1939"
                  @click="(e) => onClickActive(e, 'about_1911_1939')"
                >
                  1911-1939
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1940_1959"
                  @click="(e) => onClickActive(e, 'about_1940_1959')"
                >
                  1940-1959
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1960_1980"
                  @click="(e) => onClickActive(e, 'about_1960_1980')"
                >
                  1960-1970
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1971_1988"
                  @click="(e) => onClickActive(e, 'about_1971_1988')"
                >
                  1971-1994
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_2000s"
                  @click="(e) => onClickActive(e, 'about_2000s')"
                >
                  1995-2004
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_2000_2024"
                  @click="(e) => onClickActive(e, 'about_2000_2024')"
                >
                  2005-2024
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_2015"
                  @click="(e) => onClickActive(e, 'about_2015')"
                >
                  Centro Stile
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_leoncino"
                  @click="(e) => onClickActive(e, 'about_leoncino')"
                >
                  Leoncino
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_trk"
                  @click="(e) => onClickActive(e, 'about_trk')"
                >
                  TRK
                </span>

                <!-- Buy now -->
                <Btn
                  v-if="store.enableShop && inventoryTotal >= 1"
                  class="px-10 ms-6"
                  @click.prevent="openInventoriesModal"
                >
                  {{ $t("common.buy") }}
                </Btn>
              </div>
            </div>
            <div
              class="flex flex-col justify-between py-4 md:flex-row lg:border-t lg:border-dark-gray-200"
              v-else
            >
              <div
                class="flex flex-row gap-8 pr-5 leading-7 flex-nowrap subsections lg:pr-0"
              >
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1911_1939"
                  @click="(e) => onClickActive(e, 'about_1911_1939')"
                >
                  1911-1939
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1940_1959"
                  @click="(e) => onClickActive(e, 'about_1940_1959')"
                >
                  1940-1959
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1960_1980"
                  @click="(e) => onClickActive(e, 'about_1960_1980')"
                >
                  1960-1980
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_1971_1988"
                  @click="(e) => onClickActive(e, 'about_1971_1988')"
                >
                  1971-1988
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_2000s"
                  @click="(e) => onClickActive(e, 'about_2000s')"
                >
                  2000S
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_2000_2024"
                  @click="(e) => onClickActive(e, 'about_2000_2024')"
                >
                  2000-2024
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_2015"
                  @click="(e) => onClickActive(e, 'about_2015')"
                >
                  2015
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_leoncino"
                  @click="(e) => onClickActive(e, 'about_leoncino')"
                >
                  Leoncino
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_trk"
                  @click="(e) => onClickActive(e, 'about_trk')"
                >
                  TRK
                </span>
                <span
                  class="text-black scrollactive-item"
                  :class="scrollItemClass"
                  data-id="about_tornado"
                  @click="(e) => onClickActive(e, 'about_tornado')"
                >
                  Tornado
                </span>

                <!-- Buy now -->
                <Btn
                  v-if="store.enableShop && inventoryTotal >= 1"
                  class="px-10 ms-6"
                  @click.prevent="openInventoriesModal"
                >
                  {{ $t("common.buy") }}
                </Btn>
              </div>
            </div>
          </template>
        </ScrollActive>
      </client-only>
    </nav>
  </section>
</template>

<script setup>
import { useMainStore } from "@/store/index";

const ChevronDownIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/chevron-down.svg?component")
);
const store = useMainStore();

const scrolled = ref(0);

const scrollItemClass = computed(() => {
  const locale = store.locale;
  if (locale === "es" || locale === "gr") {
    return "text-xs lg:text-sm";
  }
  return "text-sm lg:text-base";
});

onBeforeMount(() => {
  if (process.browser) {
    window.addEventListener("scroll", handleScroll, false);
  }
});

onBeforeUnmount(() => {
  if (process.browser) {
    window.removeEventListener("scroll", handleScroll);
  }
});

function handleScroll(e) {
  scrolled.value = window.pageYOffset !== undefined ? window.scrollY : "";
}
</script>

<style lang="postcss">
.v--modal-block-scroll {
  @apply overflow-y-hidden;

  .about__subnav {
    @apply hidden;
  }
}

.about__subnav {
  .subsections span {
    animation: fade 0.3s ease-in-out;

    &.scrollactive-item {
      @apply font-myriad-pro-condensed text-[15px] lg:text-lg italic text-black uppercase !no-underline;

      &.is-active {
        @apply text-primary-500  !no-underline;
      }
    }
  }
}
</style>
