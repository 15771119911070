import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "__Group" }, [
      _createElementVNode("path", {
        id: "__Vector",
        d: "M9.16602 1.66663C13.306 1.66663 16.666 5.02663 16.666 9.16663C16.666 13.3066 13.306 16.6666 9.16602 16.6666C5.02602 16.6666 1.66602 13.3066 1.66602 9.16663C1.66602 5.02663 5.02602 1.66663 9.16602 1.66663ZM9.16602 15C12.3885 15 14.9993 12.3891 14.9993 9.16663C14.9993 5.94329 12.3885 3.33329 9.16602 3.33329C5.94268 3.33329 3.33268 5.94329 3.33268 9.16663C3.33268 12.3891 5.94268 15 9.16602 15ZM16.2368 15.0591L18.5943 17.4158L17.4152 18.595L15.0585 16.2375L16.2368 15.0591Z",
        fill: "#807E7E"
      })
    ], -1)
  ])))
}
export default { render: render }