<template>
  <ModalWrapper
    modal-body-class=" p-6 mx-auto overflow-y-auto max-h-[95dvh]  overscroll-contain  bg-white max-w-[36rem]"
  >
    <div v-if="props.policyData" class="test-drive-privacy-policy">
      <h1 class="mt-0 text-2xl lg:text-4xl" v-text="props.policyData.title" />
      <div v-html="props.policyData.content" />
    </div>
  </ModalWrapper>
</template>
<script setup>
const props = defineProps({
  policyData: {
    required: true,
    default: null,
  },
});
</script>
<style>
.test-drive-privacy-policy p,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
ul {
  @apply my-4;
}
.test-drive-privacy-policy h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold;
}
.test-drive-privacy-policy ul {
  @apply list-disc pl-6;
}
</style>
