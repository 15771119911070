<template>
  <div>
    <BecomeHeroSection
      :title="$t('common.become_distributor.become_a')"
      :secondaryTitle="$t('common.become_distributor.distributor')"
      :description="
        $t('common.become_distributor.hero_section_description', {
          brand_name: 'Benelli ',
          country: defaultContryName,
          interpolation: { prefix: '[:', suffix: ']' },
        })
      "
      imageUrl="distributor"
    />
    <BecomeSubHeroSection
      :title="
        $t('common.become_distributor.completing_initial_application_title')
      "
      :description="
        $t(
          'common.become_distributor.completing_initial_application_description'
        )
      "
      :userContent="{
        title: $t('common.become_distributor.meeting_requirements_title'),
        description: $t(
          'common.become_distributor.meeting_requirements_description',
          {
            brand_name: 'Benelli ',
            interpolation: { prefix: '[:', suffix: ']' },
          }
        ),
      }"
      :screenContent="{
        title: $t('common.become_distributor.presenting_title'),
        description: $t('common.become_distributor.presenting_description', {
          brand_name: 'Benelli ',
          interpolation: { prefix: '[:', suffix: ']' },
        }),
      }"
      :contractContent="{
        title: $t('common.become_distributor.signing_title'),
        description: $t('common.become_distributor.signing_description', {
          brand_name: 'Benelli ',
          interpolation: { prefix: '[:', suffix: ']' },
        }),
      }"
    />
    <BecomeFaqs />
    <BecomeContactUs
      :title="$t('common.become_distributor.contact_us_section_title')"
      :formFieldsName="{
        your_name: $t('common.become_distributor.your_name'),
        your_company: $t('common.become_distributor.your_company'),
        your_role: $t('common.become_distributor.your_role'),
        phone_number: $t('common.become_distributor.phone_number'),
        address: $t('common.become_distributor.address'),
        message: $t('common.become_distributor.message'),
        phone_placeholder: $t('common.become_distributor.phone_placeholder'),
        become_distributor_thanks: $t(
          'common.become_distributor.become_distributor_thanks'
        ),
        country: $t('common.country'),
      }"
      imageFolder="distributor"
    />
  </div>
</template>
<script setup>
import { useLocaleStore } from "~/store/locales";
import { useTranslation } from "i18next-vue";

const localeStore = useLocaleStore();
const { t } = useTranslation();

const defaultContryName = localeStore.getDefaultContryName;
</script>
