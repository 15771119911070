import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#__clip0_1071_75674)\"><path d=\"M11.9987 5.33334H6.66536C5.92898 5.33334 5.33203 5.9303 5.33203 6.66668V12C5.33203 12.7364 5.92898 13.3333 6.66536 13.3333H11.9987C12.7351 13.3333 13.332 12.7364 13.332 12V6.66668C13.332 5.9303 12.7351 5.33334 11.9987 5.33334Z\" stroke=\"#99A2AF\" stroke-width=\"1.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M10.668 5.33332V3.99999C10.668 3.64637 10.5275 3.30723 10.2774 3.05718C10.0274 2.80713 9.68826 2.66666 9.33464 2.66666H4.0013C3.64768 2.66666 3.30854 2.80713 3.05849 3.05718C2.80844 3.30723 2.66797 3.64637 2.66797 3.99999V9.33332C2.66797 9.68695 2.80844 10.0261 3.05849 10.2761C3.30854 10.5262 3.64768 10.6667 4.0013 10.6667H5.33464\" stroke=\"#99A2AF\" stroke-width=\"1.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g><defs><clipPath id=\"__clip0_1071_75674\"><rect width=\"16\" height=\"16\" fill=\"white\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }