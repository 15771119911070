<template>
  <section>
    <div
      class="container max-w-3xl py-6 min-h-[500px] justify-center flex flex-col"
    >
      <div
        v-if="success"
        class="flex items-center w-24 h-24 mx-auto mb-3 text-green-500 border-4 border-green-500 rounded-full"
      >
        <CheckIcon class="w-24 h-24" />
      </div>
      <div
        v-else
        class="flex items-center w-24 h-24 mx-auto mb-3 text-red-500 border-4 border-red-500 rounded-full"
      >
        <CloseIcon class="w-24 h-24" />
      </div>

      <div
        v-if="success"
        class="w-full px-4 py-4 mt-6 text-green-500 bg-green-100 border border-green-500 rounded-md shadow"
      >
        <p class="text-lg">
          {{ $t("common.newsletter_thanks") }}
        </p>
      </div>
      <div
        v-else
        class="w-full px-4 py-4 mt-6 text-red-500 bg-red-100 border border-red-500 rounded-md shadow"
      >
        <p class="text-lg">
          {{ $t("common.newsletter_failed") }}
        </p>
      </div>

      <nuxt-link
        :to="$i18nPath('/')"
        class="py-2 mt-6 text-primary-500"
        :prefetch="false"
      >
        {{ $t("common.visit_homepage") }}
      </nuxt-link>
    </div>
  </section>
</template>

<script setup>
import CheckIcon from "~/assets/svg/heroicons/check.svg?component";
import CloseIcon from "~/assets/svg/heroicons/close.svg?component";

const nuxtApp = useNuxtApp();
const success = ref(false);
const route = useRoute();

urlCallbackFunc();

async function urlCallbackFunc() {
  try {
    if (!process.browser) return false;
    const searchParams = new URLSearchParams(window.location.search);

    const url = searchParams.get("url");
    const urlCallback = decodeURIComponent((url + "").replace(/\+/g, "%20"));

    const responseData = await nuxtApp.$api(urlCallback);
    success.value = true;
    nuxtApp.$toast.success(responseData.data);
  } catch (error) {
    console.log(error);
  }
}
</script>

