<template>
  <nuxt-link
    v-if="view == 'card'"
    class="relative group cursor-pointer hover:no-underline border-b border-[#EAEDF3] pb-2"
    :to="$i18nPath(`/news/${post.slug}`)"
    :prefetch="false"
  >
    {{ post.seo.url_with_slug }}
    <div
      class="block w-full overflow-hidden transition-all ease-in bg-gray-100 aspect-w-10 aspect-h-7 group-hover:ring-2 group-hover:ring-offset-2 group-hover:ring-offset-gray-100 group-hover:ring-primary-500"
    >
      <img
        v-if="post.cover"
        :src="post.cover.versions.md"
        alt=""
        class="object-cover h-full pointer-events-none group-hover:opacity-75 !group-hover:border-none"
      />
    </div>

    <div class="w-full mt-6 mb-3.5">
      <div class="flex justify-between items-start w-full leading-none mb-3.5">
        <div class="flex flex-wrap gap-1.5">
          <span
            v-if="post.tags.length > 0"
            v-for="(tag, tagIndex) in post.tags"
            :key="tagIndex"
            class="px-[10px] pt-[2px] pb-[2px] !text-[10px] leading-5 mr-1 md:text-xs font-myriad-pro text-black uppercase border rounded-full me-1"
            v-text="tag"
          />
        </div>
        <div class="flex items-center justify-end min-w-[130px]">
          <span
            class="float-right mb-auto ml-auto mr-2 text-sm uppercase text-secondary-dark text-opacity-20"
            ><svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.4" clip-path="url(#clip0_4493_70612)">
                <path
                  d="M3 5.75C3 5.35218 3.15804 4.97064 3.43934 4.68934C3.72064 4.40804 4.10218 4.25 4.5 4.25H13.5C13.8978 4.25 14.2794 4.40804 14.5607 4.68934C14.842 4.97064 15 5.35218 15 5.75V14.75C15 15.1478 14.842 15.5294 14.5607 15.8107C14.2794 16.092 13.8978 16.25 13.5 16.25H4.5C4.10218 16.25 3.72064 16.092 3.43934 15.8107C3.15804 15.5294 3 15.1478 3 14.75V5.75Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 2.75V5.75"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 2.75V5.75"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.75H15"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 11.75H7.5V13.25H6V11.75Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_4493_70612">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span
            class="float-right mb-[1px] mt-[1px] text-sm font-normal text-black capitalize font-myriad-pro"
            v-text="getMonthYearFromDate(post.published_at)"
          >
          </span>
        </div>
      </div>
      <span
        class="leading-[34px] mt-3 text-base md:text-xl font-myriad-pro !font-semibold text-black text-underline-offset group-hover:underline group-hover:bg-primary group-hover:text-white"
        v-text="post.title"
      />

      <!-- eslint-disable-next-line -->
      <p
        class="mt-3 overflow-hidden text-sm font-normal md:text-base font-myriad-pro text-secondary-dark text-opacity-60 line-clamp-2"
        v-html="post.excerpt"
      />
    </div>
  </nuxt-link>

  <nuxt-link
    v-else-if="view == 'list'"
    class="relative w-full cursor-pointer group hover:no-underline"
    :to="$i18nPath(`/news/${post.slug}`)"
    :prefetch="false"
  >
    <div class="flex items-center w-full pt-6 pb-6">
      <div class="xl:w-[340px] xl:h-[209px] h-[169px] w-[280px] bg-gray-100">
        <img
          v-if="post.cover"
          :src="post.cover.versions.md"
          alt=""
          class="object-cover w-full h-full pointer-events-none group-hover:opacity-75 !group-hover:border-none"
        />
      </div>

      <div class="flex-1 w-full mt-2 ml-10">
        <div class="items-start w-full leading-none md:flex mb-3.5">
          <div class="flex flex-wrap gap-1.5">
            <span
              v-if="post.tags.length > 0"
              v-for="(tag, tagIndex) in post.tags"
              :key="tagIndex"
              class="px-[10px] pt-[2px] pb-[2px] !text-[10px] mr-1 md:text-xs font-myriad-pro italic !leading-[13px] text-black uppercase border rounded-full me-1"
              v-text="tag"
            />
          </div>
          <div
            class="flex items-center justify-end mt-4 md:ml-auto md:mt-0 lg:mt-0 sm:mt-10 min-w-[130px]"
          >
            <span
              class="float-left mb-0 ml-auto mr-1.5 text-sm text-gray-500 uppercase"
            >
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4" clip-path="url(#clip0_4493_70612)">
                  <path
                    d="M3 5.75C3 5.35218 3.15804 4.97064 3.43934 4.68934C3.72064 4.40804 4.10218 4.25 4.5 4.25H13.5C13.8978 4.25 14.2794 4.40804 14.5607 4.68934C14.842 4.97064 15 5.35218 15 5.75V14.75C15 15.1478 14.842 15.5294 14.5607 15.8107C14.2794 16.092 13.8978 16.25 13.5 16.25H4.5C4.10218 16.25 3.72064 16.092 3.43934 15.8107C3.15804 15.5294 3 15.1478 3 14.75V5.75Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 2.75V5.75"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 2.75V5.75"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 8.75H15"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 11.75H7.5V13.25H6V11.75Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4493_70612">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>

            <span
              class="mb-[1px] mt-[1px] text-sm capitalize md:float-right text-black font-myriad-pro font-normal"
              v-text="getMonthYearFromDate(post.published_at)"
            >
            </span>
          </div>
        </div>

        <span
          class="md:leading-[40px] mt-3 text-base md:text-xl font-myriad-pro !font-semibold text-black text-underline-offset group-hover:underline group-hover:bg-primary group-hover:text-white"
          v-text="post.title"
        />

        <!-- eslint-disable-next-line -->
        <p
          class="mt-3 overflow-hidden text-sm font-normal line-clamp-2 font-myriad-pro md:text-base text-secondary-dark text-opacity-60"
          v-html="post.excerpt"
        />
      </div>
    </div>
  </nuxt-link>
</template>

<script setup>
const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  view: {
    default: "list",
    type: String,
  },

  theme: {
    default: "light",
    type: String,
  },
});

function getMonthYearFromDate(publishedDate) {
  const date = new Date(publishedDate);

  const month = date.getMonth();
  const year = date.getFullYear();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthName = monthNames[month];

  return `${monthName} ${year}`;
}
</script>

<style>
.text-underline-offset {
  text-underline-offset: 4px;
}
</style>
