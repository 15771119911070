<template>
  <div>
    <ul
      class="relative m-0 grid grid-cols-3 py-[5px] px-[5px] list-none rounded-xl bg-[#F4F4F6]"
    >
      <li
        v-for="(tab, k) in props.tabs"
        :key="k"
        class="relative flex-auto w-full text-center tab"
        @click="switchTab(tab, k)"
      >
        <a
          :class="
            isActiveTab(tab)
              ? 'text-black font-myriad-pro font-normal'
              : 'text-dark-gray font-myriad-pro font-light'
          "
          class="z-30 flex items-center justify-center w-full px-3 py-2 mb-0 text-sm font-medium no-underline transition-all ease-in-out border-0 rounded-lg cursor-pointer lg:text-base md:px-4 xl:px-5 hover:text-black hover:no-underline text-slate-700 bg-inherit"
        >
          <span class="z-20 w-full tooltip">
            <span class="inline-block w-full truncate tab">
              {{ tab }}
            </span>
            <span class="tooltiptext" v-if="tab.length > 8">{{ tab }}</span>
          </span>
          <span
            class="absolute w-[95%] h-[90%] transition-all duration-300 rounded-lg"
            :class="hoverClass(tab, k)"
          />
        </a>
      </li>
    </ul>
    <div>
      <slot :name="activeTab" />
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
    default: Array,
  },
});

const emit = defineEmits();

const activeTab = ref(props.tabs[0]);
const currentIndex = ref(0);

const switchTab = (tab, index) => {
  if (currentIndex.value === index) {
    return false;
  }
  currentIndex.value = index;
  activeTab.value = tab;
  emit("activeTab", tab);
};

const isActiveTab = (tab) => {
  return activeTab.value === tab;
};
const hoverClass = (tab, index) => {
  return isActiveTab(tab)
    ? "bg-white tab-shadow truncate inline-block z-10"
    : currentIndex.value < index
    ? "-translate-x-full z-[-1]"
    : "translate-x-full z-[-1]";
};
</script>
<style scoped>
.tooltip {
  @apply flex justify-center items-center relative;
}
.tab-shadow {
  box-shadow: 0px 2px 2px 0px rgba(215, 218, 226, 0.79);
}
/* Tooltip text */
.tooltip .tooltiptext {
  top: calc(100% + 15px);
  @apply absolute py-[5px] px-2.5 text-center text-white font-myriad-pro font-light bg-black w-auto visible rounded-md shadow whitespace-nowrap z-[9999] transition-opacity delay-300 opacity-0 left-auto right-auto;
}

/* Show the tooltip text on hover */
.tooltip:hover .tooltiptext {
  @apply opacity-100 visible;
}
.tooltip .tooltiptext::after {
  content: " ";
  border-color: transparent transparent black transparent;
  @apply absolute bottom-full left-1/2 ml-[-5px] border-[5px] border-solid;
}
</style>
