import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#__clip0_1071_75532)" }, [
      _createElementVNode("path", {
        d: "M9.16797 1.66667C13.308 1.66667 16.668 5.02667 16.668 9.16667C16.668 13.3067 13.308 16.6667 9.16797 16.6667C5.02797 16.6667 1.66797 13.3067 1.66797 9.16667C1.66797 5.02667 5.02797 1.66667 9.16797 1.66667ZM9.16797 15C12.3905 15 15.0013 12.3892 15.0013 9.16667C15.0013 5.94334 12.3905 3.33334 9.16797 3.33334C5.94464 3.33334 3.33464 5.94334 3.33464 9.16667C3.33464 12.3892 5.94464 15 9.16797 15ZM16.2388 15.0592L18.5963 17.4158L17.4171 18.595L15.0605 16.2375L16.2388 15.0592Z",
        fill: "#807E7E"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "__clip0_1071_75532" }, [
        _createElementVNode("rect", {
          width: "20",
          height: "20",
          fill: "white"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }