<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clip-path="url(#clip0_4487_3278)">
      <path
        id="Vector"
        d="M18.6665 4V9.33333C18.6665 9.68695 18.807 10.0261 19.057 10.2761C19.3071 10.5262 19.6462 10.6667 19.9998 10.6667H25.3332"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M22.6665 28H9.33317C8.62593 28 7.94765 27.719 7.44755 27.219C6.94746 26.7189 6.6665 26.0406 6.6665 25.3333V6.66667C6.6665 5.95942 6.94746 5.28115 7.44755 4.78105C7.94765 4.28095 8.62593 4 9.33317 4H18.6665L25.3332 10.6667V25.3333C25.3332 26.0406 25.0522 26.7189 24.5521 27.219C24.052 27.719 23.3737 28 22.6665 28Z"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M12 12H13.3333"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M12 17.3334H20"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M12 22.6666H20"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4487_3278">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
