<template>
  <section>
    <ManualsHeaderSection
      :title="$t('common.manuals')"
      :view="view"
      @view="setView"
      @filter="filterPost"
    />

    <div id="overlay" @click="hideMobileMenu" v-show="hasOverlay"></div>

    <!-- Alphabets -->
    <div class="w-full py-5 lg:container">
      <div
        class="flex w-full px-4 overflow-x-auto lg:px-0 no-scrollbar overscroll-none"
      >
        <div
          class="flex lg:items-center w-auto justify-center gap-3 lg:gap-[10px] xl:gap-[9.7px]"
        >
          <span
            v-for="(alpha, index) in alphabets"
            :key="index"
            :class="[
              alpha
                ? ' text-dark-gray-100 hover:bg-primary-500 hover:text-white'
                : 'bg-[#F1F1F1] text-[#BCBCBC] pointer-events-none',
              { 'bg-primary-500 !text-white': isAlpha == index },
            ]"
            class="lg:w-[30px] lg:h-[30px] pt-[3px] cursor-pointer min-w-[36px] lg:min-w-[30px] w-9 h-9 font-myriad-pro border border-[#F1F1F1] rounded-full flex-1 flex items-center justify-center"
            @click="getAlphabetManuals(alpha, index)"
          >
            {{ index }}
          </span>
        </div>
      </div>
    </div>

    <hr class="border-[#EAEDF3]" />

    <NotFound
      v-if="manuals.length == 0 && !loading"
      :title="$t('common.sorry_text')"
      :description="$t('common.no_manuals')"
    />

    <div
      :class="[
        view == 'card' ? 'mb-[60px]' : 'mb-[60px] md:mb-[80px]',
        'mt-5 md:mt-6 ',
      ]"
      v-else-if="manuals.length && isSizeUpdated"
    >
      <div class="container lg:mt-10 mt-[30px]">
        <XyzTransitionGroup
          id="manual-row"
          :class="[
            showCard
              ? 'grid grid-cols-1 gap-x-3 gap-y-[30px] md:grid-cols-2 lg:grid-cols-3 md:gap-x-6 md:gap-y-6'
              : '',
          ]"
          xyz="fade duration-5 appear-front-3 small-3 appear-small-0 stagger-0"
          appear-visible
        >
          <!-- :class="
            showCard &&
            (key === manuals.length - 1 || key === manuals.length - 2)
              ? ''
              : 'border-b border-[#EAEDF3]'
          " -->
          <div v-for="(manual, key) in manuals" :key="key">
            <ManualPostItem :post="manual" :view="view" />
          </div>
        </XyzTransitionGroup>
      </div>
    </div>

    <!-- Load more Manuals -->
    <div
      :class="[
        view == 'card' ? 'mb-[60px]' : 'mb-[60px] md:mb-[80px]',
        'container',
      ]"
    >
      <Spinner
        v-if="loading"
        :class="[currentPage == 1 ? 'min-h-[504px]' : 'mb-20']"
        class="m-auto animate-spin !mt-10 w-8 h-8"
      />
      <div
        v-if="showMore && isSizeUpdated"
        class="text-center relative !min-h-[50px] w-[100%] border-b border-[#EAEDF3] flex justify-center items-center"
      >
        <button
          v-if="showMore"
          class="text-black absolute uppercase text-sm font-semibold m-auto !bg-[#F4F4F4] bottom-[-25px] border-1 rounded-full pl-10 pr-10 pt-3 pb-3 !hover:text-black"
          @click="loadMore()"
        >
          {{ $t("common.see_more") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import SpinnerIcon from "~/assets/svg/spinner.svg?component";
import { useAPI } from "~/composables/useAPI";

const { t } = useTranslation();

const view = ref("list");
const closeMenu = ref(true);
const alphabets = ref([]);
const manuals = ref([]);
const hasOverlay = ref(false);
const filtered = ref(false);
const showMore = ref(false);
const isAlpha = ref("");
const loading = ref(false);
const lastPage = ref(1);
const currentPage = ref(1);
const meta = ref(null);
const search = ref(null);
const isMobile = ref(false);
const filterInmobile = ref(false);
const isSizeUpdated = ref(false);
const showCard = ref(false);

try {
  // Index by alphabets
  let alphabetsData = await useAPI("api/front/index-by-alphabets");

  if (alphabetsData.data._rawValue) {
    const entries = Object.entries(alphabetsData.data._rawValue.data);
    const lastEntry = entries.pop();
    const newObject = Object.fromEntries([lastEntry, ...entries]);
    alphabets.value = newObject;
  }

  // Fetch manuals
  const resManData = await useAPI("api/front/manuals-by-alphabets?per_page=1");

  showMore.value = true;
  const newObj = resManData.data._rawValue;
  if (newObj.meta.current_page == newObj.meta.last_page) {
    showMore.value = false;
  }

  manuals.value = newObj.data;
  loading.value = true;
  currentPage.value = newObj.meta.current_page;
  lastPage.value = newObj.meta.last_page;
  meta.value = newObj.meta;
  search.value = "";
  isSizeUpdated.value = false;
} catch (e) {
  console.log(e, "error");
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

onMounted(() => {
  if (process.browser) {
    updateSize();

    if (isMobile.value) {
      filterInmobile.value = true;
      view.value = "card";
    }
  }
});

async function getAlphabetManuals(alpha, index) {
  currentPage.value = 1;
  manuals.value = [];
  loading.value = true;
  showMore.value = false;
  hasOverlay.value = false;
  if (index == isAlpha.value) {
    isAlpha.value = "";
    const { data, meta } = await useAPI(
      `api/front/manuals-by-alphabets?letter=&search=&page=1&per_page=10`
    );

    const newObj = data._rawValue;

    showMore.value = true;

    if (newObj.meta.current_page == newObj.meta.last_page) {
      showMore.value = false;
    }

    loading.value = false;
    manuals.value = newObj.data;
    return;
  } else {
    isAlpha.value = index;
    filtered.value = true;
    try {
      useAPI(
        `api/front/manuals-by-alphabets?letter=${index}&search=${search.value}&per_page=10&page=1`
      ).then(({ data, meta }) => {
        if (data._rawValue) {
          const newObj = data._rawValue;
          if (newObj.data.length == 0) {
            showMore.value = false;
          }

          manuals.value = newObj.data;

          if (newObj.meta.current_page == newObj.meta.last_page) {
            showMore.value = false;
          } else {
            showMore.value = true;
          }
          loading.value = false;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
}

function updateSize() {
  if (window.innerWidth <= 760) {
    isMobile.value = true;
    filterInmobile.value = true;
    view.value = "card";
    showCard.value = true;
  } else {
    filterInmobile.value = false;
    if (view.value == "card" && isMobile.value) {
      view.value = "card";
      showCard.value = false;
    }

    isMobile.value = false;
  }
  loading.value = false;
  isSizeUpdated.value = true;
}

function clearFilter() {
  filtered.value = false;
  currentPage.value = 1;
  loadMore();
}

function hideMobileMenu() {
  hasOverlay.value = false;
}

function setView(e) {
  view.value = e;
  if (view.value == "card") {
    showCard.value = true;
  } else {
    showCard.value = false;
  }
}

function checkFilterWitMore() {
  currentPage.value = currentPage.value + 1;
  if (search.value !== "") {
    filterPost();
  }
}

function filterPost(e) {
  search.value = e;
  loading.value = true;
  showMore.value = false;
  hasOverlay.value = false;
  filtered.value = true;
  currentPage.value = 1;
  manuals.value = [];
  try {
    useAPI(
      `api/front/manuals-by-alphabets?per_page=10&page=1&search=${e}&letter=${isAlpha.value}`
    ).then(({ data }) => {
      if (data._rawValue) {
        const newObj = data._rawValue;
        if (newObj.data.length == 0) {
          showMore.value = false;
        }
        manuals.value = newObj.data;

        if (newObj.meta.current_page == newObj.meta.last_page) {
          showMore.value = false;
        } else {
          showMore.value = true;
        }

        loading.value = false;
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function loadMore() {
  showMore.value = false;
  loading.value = true;
  let url = "";
  currentPage.value = currentPage.value + 1;
  url = `api/front/manuals-by-alphabets?letter=${isAlpha.value}&search=${search.value}&per_page=10&page=${currentPage.value}`;
  try {
    if (currentPage.value == 1) {
      manuals.value = [];
    }
    useAPI(url).then(({ data }) => {
      const newObj = data._rawValue;
      currentPage.value = newObj.meta.current_page;
      if (currentPage.value == 1) {
        manuals.value = newObj.data;
      } else {
        manuals.value = [...manuals.value, ...newObj.data];
      }
      if (newObj.meta.current_page == newObj.meta.last_page) {
        showMore.value = false;
      } else {
        showMore.value = true;
      }
      setTimeout(() => {
        loading.value = false;
        hideMobileMenu();
      }, 100);
    });
  } catch (error) {
    console.error(error);
  }
}
</script>
