<template>
  <section v-if="isSuccess">
    <div
      class="container flex flex-col items-center justify-center py-[59px] bg-white unsubscribe"
    >
      <UnsubscribeSuccessIcon class="md:w-[172px] md:h-[172px] mb-[30px]" />

      <h2
        class="inline mb-4 text-[28px] lg:w-[750px] w-full font-myriad-pro-condensed font-normal !leading-[34px] italic uppercase md:text-[33px] md:!leading-[40px] text-center text-[#000] sm:block"
      >
        {{ $t("common.subscriber_cancelled_success") }}
      </h2>
      <p
        class="text-base font-normal font-myriad-pro leading-[26px] text-center flex justify-center items-center"
      >
        {{
          $t("common.unsubscribe_success_description", {
            brand_name: $config.APP_NAME,
            country_name: countryName,
            interpolation: { prefix: "[:", suffix: "]" },
          })
        }}
      </p>

      <div class="mt-[30px]">
        <Btn
          :to="$i18nPath('/')"
          color="primary"
          block
          size="lg"
          v-text="$t('common.go_to_the_home')"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import UnsubscribeSuccessIcon from "~/assets/svg/heroicons/unsubscriber-success-icon.svg?component";
import { useMainStore } from "~/store";
import { useLocaleStore } from "~/store/locales";

const props = defineProps({
  isSuccess: {
    type: Boolean,
    default: true,
  },
});
const store = useMainStore();
const localeStore = useLocaleStore();
const nuxtApp = useNuxtApp();

const $i18nPath = nuxtApp.$i18nPath;

const countryName = computed(() => {
  const country = localeStore.locales.length
    ? localeStore.locales.find((x) => x.slug === localeStore.country)
    : null;
  return country ? country.country.name : "";
});
</script>
