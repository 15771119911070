<template>
  <section class="become-contactus" id="become-contactus">
    <div class="relative w-full">
      <div class="lg:py-[100px] py-[60px] container">
        <h1
          class="text-[#000] italic text-[26px] text-center md:text-left w-full xl:text-[33px] leading-[30px] xl:leading-[48px] font-myriad-pro font-semibold uppercase"
          v-text="title"
        ></h1>
        <div class="flex flex-col gap-6 mt-4 lg:mt-10 md:flex-row-reverse">
          <div
            :class="[
              imageFolder === 'dealer'
                ? 'become-dealer-contactus-img'
                : 'become-distributor-contactus-img',
              mainStore.country == 'int' ? 'int' : 'no-int',
            ]"
          ></div>

          <div class="relative w-full">
            <form
              id="become_dealer"
              action="#"
              method="POST"
              @submit.prevent="onSubmit"
            >
              <div class="space-y-[28px]">
                <div class="grid grid-cols-1">
                  <label
                    for=""
                    class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                    v-text="formFieldsName.your_name"
                  ></label>

                  <input
                    v-model="form.first_name"
                    type="text"
                    name=""
                    :class="[
                      errorsObj && errorsObj.first_name ? 'is-invalid' : '',
                      'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] font-myriad-pro text-black font-normal placeholder:text-[#848484]',
                    ]"
                    :placeholder="$t('common.type_your_answer')"
                    @input="clearValidation('first_name')"
                  />
                  <p
                    v-if="errorsObj && errorsObj.first_name"
                    class="mt-1 text-sm text-red-500"
                    v-text="errorsObj.first_name[0]"
                  />
                </div>
                <div class="grid grid-cols-1">
                  <label
                    for=""
                    class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                    v-text="formFieldsName.your_company"
                  ></label>
                  <input
                    v-model="form.company_name"
                    type="text"
                    name=""
                    :class="[
                      { 'is-invalid': errorsObj && errorsObj.company_name },
                      'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] font-myriad-pro text-black font-normal placeholder:text-[#848484]',
                    ]"
                    :placeholder="$t('common.type_your_answer')"
                    @input="clearValidation('company_name')"
                  />
                  <p
                    v-if="errorsObj && errorsObj.company_name"
                    class="mt-1 text-sm text-red-500"
                    v-text="errorsObj.company_name[0]"
                  />
                </div>
                <div class="grid grid-cols-1">
                  <label
                    for=""
                    class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                    v-text="formFieldsName.your_role"
                  ></label>
                  <input
                    v-model="form.occupation"
                    type="text"
                    name=""
                    :class="[
                      { 'is-invalid': errorsObj && errorsObj.occupation },
                      ,
                      'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] font-myriad-pro text-black font-normal placeholder:text-[#848484]',
                    ]"
                    :placeholder="$t('common.type_your_answer')"
                    @input="clearValidation('occupation')"
                  />
                  <p
                    v-if="errorsObj && errorsObj.occupation"
                    class="mt-1 text-sm text-red-500"
                    v-text="errorsObj.occupation[0]"
                  />
                </div>
                <div class="grid grid-cols-1">
                  <label
                    for="contact_phone_number"
                    class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                    v-text="formFieldsName.phone_number"
                  ></label>
                  <VueTelInput
                    id="phone"
                    v-model="form.phone"
                    input-id="contact_phone_number"
                    mode="international"
                    autocomplete="phone"
                    :default-country="mainStore.country"
                    :input-options="options"
                    :class="[
                      { 'is-invalid': errorsObj && errorsObj.phone },
                      'phone',
                    ]"
                    @input="clearValidation('phone')"
                    @open="isDropdownOpen = true"
                    @close="isDropdownOpen = false"
                  >
                    <template #arrow-icon>
                      <ArrowDownSLineIcon
                        class="text-[#020202] w-5 h-5 transform transition-transform"
                        :class="isDropdownOpen ? 'rotate-180' : ''"
                      />
                    </template>
                  </VueTelInput>
                  <p
                    v-if="errorsObj && errorsObj.phone"
                    class="mt-1 text-sm text-red-500"
                    v-text="errorsObj.phone[0]"
                  />
                </div>
                <div class="grid grid-cols-1">
                  <label
                    for=""
                    class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                    v-text="formFieldsName.address"
                  ></label>
                  <input
                    v-model="form.email"
                    type="text"
                    name=""
                    :class="[
                      { 'is-invalid': errorsObj && errorsObj.email },
                      'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] font-myriad-pro text-black font-normal placeholder:text-[#848484]',
                    ]"
                    placeholder="name@example.com"
                    @input="clearValidation('email')"
                  />
                  <p
                    v-if="errorsObj && errorsObj.email"
                    class="mt-1 text-sm text-red-500"
                    v-text="errorsObj.email[0]"
                  />
                </div>
                <div v-if="mainStore.country == 'int'" class="sm:col-span-2">
                  <!-- Country -->
                  <div class="w-full">
                    <label
                      for="country"
                      class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                      v-text="formFieldsName.country"
                    />
                    <div class="relative mt-1 rounded-md">
                      <div class="mt-1">
                        <vue-select
                          ref="select"
                          v-model="form.country_code"
                          :options="countries"
                          label="name"
                          class="shadow-sm vue-select-chooser"
                          :class="[
                            {
                              'is-invalid': errorsObj && errorsObj.country_code,
                            },
                          ]"
                          :reduce="(country) => country.id"
                          :placeholder="$t('common.placeholder')"
                          @update:modelValue="clearValidation('country_code')"
                        >
                          <template #option="option">
                            <div class="flex items-center text">
                              <span class="text-gray-500">{{
                                option.flag
                              }}</span>
                              <span class="ml-2">{{ option.name }}</span>
                            </div>
                          </template>
                        </vue-select>
                        <p
                          v-if="errorsObj && errorsObj.country_code"
                          class="mt-1 text-sm text-red-400"
                          v-text="errorsObj.country_code[0]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1">
                  <label
                    for=""
                    class="text-[#020202] font-myriad-pro font-semibold text-[11px] leading-[20px] uppercase"
                    v-text="formFieldsName.message"
                  ></label>
                  <input
                    v-model="form.message"
                    type="text"
                    name=""
                    :class="[
                      { 'is-invalid': errorsObj && errorsObj.message },
                      'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] font-myriad-pro text-black font-normal placeholder:text-[#848484]',
                    ]"
                    :placeholder="$t('common.type_your_answer')"
                    @input="clearValidation('message')"
                  />
                  <p
                    v-if="errorsObj && errorsObj.message"
                    class="mt-1 text-sm text-red-500"
                    v-text="errorsObj.message[0]"
                  />
                </div>
                <div class="justify-center hidden md:justify-start">
                  <vue-hcaptcha
                    ref="invisibleHcaptcha"
                    size="invisible"
                    :sitekey="$config.HCAPTCHA_SITE_KEY"
                    theme="light"
                    :language="mainStore.locale"
                    @verify="onVerify"
                    @expired="onExpire"
                    @challengeExpired="onExpire"
                    @error="onError"
                    @closed="handleClose"
                  />
                </div>
              </div>
              <div class="mt-[32px] lg:mt-[48px]">
                <Btn
                  :disabled="isSending"
                  type="submit"
                  color="primary"
                  size="lg"
                  class="w-full uppercase lg:w-auto disabled:opacity-80"
                >
                  <span
                    class="flex items-center justify-center space-x-2 text-white uppercase font-myriad-pro"
                  >
                    <Spinner v-if="isSending" class="h-5 mr-2 animate-spin" />
                    <span> {{ $t("common.submit") }}</span>
                  </span>
                </Btn>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { VueTelInput } from "vue-tel-input";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import SpinnerIcon from "~/assets/svg/spinner.svg?component";
import ArrowDownSLineIcon from "@/assets/svg/heroicons/arrow-down-s-line.svg?component";
import { useTranslation } from "i18next-vue";
import { useMainStore } from "@/store/index";
import { useLocaleStore } from "@/store/locales";

const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const mainStore = useMainStore();
const $config = nuxtApp.$config.public;
const localeStore = useLocaleStore();

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  formFieldsName: {
    type: Object,
    default: null,
  },
  imageFolder: {
    type: String,
    default: "dealer",
  },
});

const formFields = reactive({
  first_name: "",
  company_name: "",
  occupation: "",
  phone: "",
  email: "",
  message: "",
  country_code: "",
});

let form = reactive(Object.assign({}, formFields));
const isSending = ref(false);
const isDropdownOpen = ref(false);
let errorsObj = reactive({});
let options = reactive({
  placeholder: "(201) 555-4433",
});
const countries = ref([]);
const invisibleHcaptcha = ref(null);

try {
  const { data } = await useAPI("api/front/countries");
  countries.value = data._rawValue ? data._rawValue.data : [];
} catch (e) {
  console.error(e);
  // error({ statusCode: 404 });
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

function clearValidation(field) {
  switch (field) {
    case "first_name":
      errorsObj.first_name = null;
      break;
    case "company_name":
      errorsObj.company_name = null;
      break;
    case "occupation":
      errorsObj.occupation = null;
      break;
    case "phone":
      errorsObj.phone = null;
      break;
    case "email":
      errorsObj.email = null;
      break;
    case "message":
      errorsObj.message = null;
      break;
    case "country_code":
      errorsObj.country_code = null;
      break;

    default:
      break;
  }
}

function onVerify(token, ekey) {
  const defaultCountry = localeStore.defaultLocales.find(
    (_v) => _v.slug === mainStore.country
  );
  form.company_id = defaultCountry ? defaultCountry.id : "";

  useAPI("/api/front/become-a-partner", {
    method: "POST",
    body: {
      ...form,
      "h-captcha-response": token,
    },
  })
    .then((response) => {
      if (response.error.value) {
        errorsObj = response.error.value.cause._data.errors;
        nuxtApp.$toast.error(response.error.value.cause._data.message);
        isSending.value = false;
      } else {
        form = Object.assign({}, formFields);
        form.country_code = "";
        form.phone = "";

        nuxtApp.$toast.success(
          t(props.formFieldsName.become_distributor_thanks)
        );

        isSending.value = false;

        window._iub.cons_instructions.push([
          "submit",
          {
            writeOnLocalStorage: false,
            form: {
              selector: document.getElementById("become_dealer"),
            },
            consent: {
              legal_notices: [
                {
                  identifier: "privacy_policy",
                },
                {
                  identifier: "cookie_policy",
                },
                {
                  identifier: "term",
                },
              ],
            },
          },
          {
            success(response) {
              isSending.value = false;
              // console.log(response)
            },
            error(response) {
              isSending.value = false;
              console.warning(response);
            },
          },
        ]);
      }
    })
    .catch((error) => {
      console.error(error);
      isSending.value = false;
    });
}

function handleClose() {
  isSending.value = false;
}

function onExpire() {
  nuxtApp.$toast.error("Captcha expired");
  isSending.value = false;
}

function onError() {
  isSending.value = false;
}

function onSubmit() {
  if (isSending.value) {
    return;
  }
  isSending.value = true;
  errorsObj = {};

  if (invisibleHcaptcha.value) {
    invisibleHcaptcha.value.execute();
  }
}
</script>
<style lang="postcss">
@import "vue-tel-input/vue-tel-input.css";

.become-dealer-contactus-img {
  background-image: url("@/assets/images/become-dealer/contact-us.png");
  @apply hidden bg-cover md:block h-[662px] w-full rounded-[10px] bg-center;

  &.int {
    @apply h-[778px];
  }
  &.no-int {
    @apply h-[662px];
  }
}

@media (max-width: 767px) {
  .become-dealer-contactus-img {
    background-image: url("@/assets/images/become-dealer/mobile-contact-us.png");
    @apply h-[292px] w-full bg-cover block md:hidden rounded-[10px];

    &.int,
    &.no-int {
      @apply h-[292px];
    }
  }
}

.become-distributor-contactus-img {
  background-image: url("@/assets/images/become-distributor/contact-us.png");
  @apply hidden bg-cover md:block  w-full rounded-[10px] bg-center;

  &.int {
    @apply h-[778px];
  }
  &.no-int {
    @apply h-[662px];
  }
}

@media (max-width: 767px) {
  .become-distributor-contactus-img {
    background-image: url("@/assets/images/become-distributor/mobile-contact-us.png");
    @apply w-full bg-cover block md:hidden rounded-[10px];

    &.int,
    &.no-int {
      @apply h-[292px];
    }
  }
}
.become-contactus #phone {
  @apply w-full !h-[48px] !border-gray-300 !text-black !rounded-[10px];
}
.become-contactus #phone.is-invalid {
  @apply !border-red-500;
}

.become-contactus .vue-tel-input:focus-within {
  @apply shadow-none;
}

.become-contactus .vue-tel-input input:focus {
  @apply focus:ring-primary-500 focus:border-primary-500;
}

.become-contactus .vti__dropdown:focus,
.become-contactus .vti__input:focus {
  @apply ring-primary-500 border-primary-500;
}
.become-contactus .vti__input {
  @apply !rounded-r-[10px] !text-black;
}
.become-contactus .vti__dropdown {
  @apply !border-r !border-[#d4d4d4] !rounded-l-[10px];
}
.become-contactus .vti__dropdown-list.below {
  @apply !top-[44px];
}
.become-contactus .vue-select-chooser .vs__search::placeholder,
.become-contactus .vue-select-chooser .vs__dropdown-toggle,
.become-contactus .vue-select-chooser .vs__dropdown-menu {
  @apply mt-2 w-full rounded-[10px] text-sm border-[#d4d4d4] border  font-myriad-pro font-normal;
}

.become-contactus .vue-select-chooser .vs__dropdown-toggle {
  @apply bg-opacity-10 bg-white text-[#848484] h-[48px];
}
.become-contactus .vue-select-chooser.is-invalid .vs__dropdown-toggle {
  @apply border-red-500;
}

.become-contactus
  .vue-select-chooser
  .vs__dropdown-toggle
  .vs__selected-options
  .vs__selected {
  color: black;
  opacity: 0.7;
}
.become-contactus .vue-select-chooser .vs__dropdown-toggle:focus:focus {
  @apply focus:ring-primary-500 focus:border-primary-500 border-2;
}
.become-contactus
  .vue-select-chooser
  .vs__dropdown-toggle:has(.vs__search:focus) {
  @apply focus:ring-primary-500 focus:border-primary-500 border;
}
</style>
