<template>
  <section>
    <HeaderSection :title="$t('common.unsubscribe')" />
    <div
      v-if="!isSuccess"
      class="container flex flex-col items-center justify-center py-[59px] bg-white unsubscribe"
    >
      <UnsubscribeMailIcon class="md:w-[172px] md:h-[172px] mb-[30px]" />

      <h2
        class="inline mb-4 text-[28px] md:w-[520px] md:h-[80px] w-full font-myriad-pro-condensed font-normal !leading-[34px] italic uppercase md:text-[33px] md:!leading-[40px] text-center text-[#000] sm:block"
      >
        {{
          $t("common.unsubscribe_title", {
            brand_name: $config.APP_NAME,
            country_name: countryName,
            interpolation: { prefix: "[:", suffix: "]" },
          })
        }}
      </h2>
      <form
        id="unsubscribe_form"
        class="flex-col items-center justify-center block w-full pt-4 md:flex"
        @submit.prevent="onSubmit"
      >
        <div class="flex items-center justify-center">
          <input
            id="unsubscribe_email"
            v-model="email"
            name="email"
            type="email"
            data-cons-subject="email"
            autocomplete="email"
            required
            :disabled="sending"
            class="w-full md:w-[560px] focus-within:z-10 focus:ring-primary-500 focus:border-primary-500 md:rounded-s-[10px] rounded-[10px] md:rounded-e-none text-primary-500 placeholder:text-[#0A0908] font-medium border-white h-[52px] bg-[#F6F6F6] py-3"
            :placeholder="$t('common.email')"
          />
          <Btn
            id="unsubscribe-submit"
            type="submit"
            :disabled="sending"
            size="sm"
            color="primary"
            class="h-[50px] md:block hidden rounded-s-none tracking-tighter text-sm uppercase"
          >
            <span class="text-white font-myriad-pro">
              {{ $t("common.submit") }}
            </span>
          </Btn>
        </div>

        <ErrorBag :errors="errors" class="mt-4" />
        <div v-if="message" class="p-4 mt-4 rounded-md bg-red-50">
          <span class="text-red-800">
            {{ message }}
          </span>
        </div>

        <div class="bg-gray-50">
          <VueHcaptcha
            ref="invisibleHcaptcha"
            size="invisible"
            :sitekey="$config.HCAPTCHA_SITE_KEY"
            theme="dark"
            :language="store.locale"
            @verify="onVerify"
            @expired="onExpire"
            @challengeExpired="onExpire"
            @closed="onExpire"
            @error="onError"
          />
        </div>

        <label
          class="flex items-center justify-start text-center border border-transparent cursor-pointer select-none pe-2 mt-[13px] md:block text-primary-500 unsubscribe--checkbox"
          :class="{
            'border-red-500 bg-red-50 text-red-400':
              errors && errors.accept_terms,
          }"
        >
          <input
            id="unsubscribe_tos"
            v-model="accept_terms"
            type="checkbox"
            required
            data-cons-preference="terms-and-conditions"
            class="me-3 cursor-pointer mt-2.5 md:mt-0 relative bottom-[.1rem] text-primary-600 bg-secondary-100 rounded-[2px] border-secondary-100 focus:ring-primary-600 focus:ring-2"
          />
          <!-- eslint-disable vue/no-v-html -->
          <span
            class="text-lg italic font-normal leading-7 font-myriad-pro-condensed text-secondary-dark terms unsubscribe__checkmark"
            v-html="
              store.country === 'cn' || store.country === 'it'
                ? $t('common.i_agree_with_the_terms_and_conditions').replace(
                    'legal',
                    $i18nPath(store.pageLinks['terms'])
                  )
                : $t('common.i_agree_with_privacy_policy').replace(
                    'privacy_policy',
                    $i18nPath(store.pageLinks['privacy-policy'])
                  )
            "
          />
          <!-- eslint-enable -->
        </label>
        <input
          type="hidden"
          name="unsubscribe"
          value="unsubscribe"
          data-cons-preference="unsubscribe"
        />

        <Btn
          id="unsubscribe-submit"
          type="submit"
          :disabled="sending"
          size="sm"
          color="primary"
          class="h-[50px] md:hidden mt-5 block w-full uppercase"
        >
          <span class="text-white font-myriad-pro"
            >{{ $t("common.submit") }}
          </span>
        </Btn>
      </form>
    </div>

    <!-- Unsubscriber Success Message component -->
    <UnSubscribeSuccess v-else :is-sucess="isSuccess" />
  </section>
</template>

<script setup>
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import UnsubscribeMailIcon from "~/assets/svg/heroicons/unsubscriber-mail-icon.svg?component";
import { useTranslation } from "i18next-vue";
import { useMainStore } from "~/store";
import { useLocaleStore } from "~/store/locales";

const { t } = useTranslation();
const store = useMainStore();
const localeStore = useLocaleStore();
const nuxtApp = useNuxtApp();

const $config = nuxtApp.$config.public;
const $i18nPath = nuxtApp.$i18nPath;
const email = ref(null);
const invisibleHcaptcha = ref(null);
const isSuccess = ref(false);
const accept_terms = ref(false);
const sending = ref(false);
const errors = ref({});
const message = ref(null);

const countryName = computed(() => {
  const country = localeStore.locales.length
    ? localeStore.locales.find((x) => x.slug === localeStore.country)
    : null;
  return country ? country.country.name : "";
});

function onVerify(token, ekey) {
  useAPI("/api/front/subscriber/unsubscribe", {
    method: "POST",
    body: {
      email: email.value,
      accept_terms: accept_terms.value,
      "h-captcha-response": token,
    },
  })
    .then((response) => {
      if (response.error.value) {
        message.value = response.error.value.cause._data.message;
        nuxtApp.$toast.error(message.value);
        errors.value = response.error.value.cause._data.errors;
        sending.value = false;
      } else {
        isSuccess.value = true;
        email.value = null;
        accept_terms.value = false;
        sending.value = false;
        nuxtApp.$toast.success(t("common.subscriber_cancelled_success"));

        window._iub &&
          window._iub.cons_instructions.push([
            "submit",
            {
              writeOnLocalStorage: false,
              form: {
                selector: document.getElementById("unsubscribe_form"),
              },
              consent: {
                legal_notices: [
                  {
                    identifier: "privacy_policy",
                  },
                  {
                    identifier: "cookie_policy",
                  },
                  {
                    identifier: "term",
                  },
                ],
              },
            },
            {
              success(response) {
                isSuccess.value = true;
                // console.log(response)
              },
              error(response) {
                email.value = null;
                isSuccess.value = false;
                console.warning(response);
              },
            },
          ]);
      }
    })
    .catch((error) => {
      console.error(error);
      email.value = null;
      isSuccess.value = false;
      // errors.value = error.response.data.errors;
      // message.value = error.response.data.message;
      nuxtApp.$toast.error(message.value);
      sending.value = false;
      accept_terms.value = false;
    });
}

function onExpire() {
  nuxtApp.$toast.error("Captcha expired");
  console.log("[vue-htcapcha] Expired token");
  sending.value = false;
  isSuccess.value = false;
}

function onError() {
  console.log("[vue-htcapcha] Failed to load hcaptcha");
  sending.value = false;
  isSuccess.value = false;
}

function onSubmit() {
  if (sending.value) {
    return;
  }
  sending.value = true;
  errors.value = {};

  invisibleHcaptcha.value.execute();
}
</script>

<style lang="postcss">
#unsubscribe_form .terms a {
  @apply no-underline text-secondary-dark;
  &:hover {
    @apply text-primary;
  }
}
.unsubscribe--checkbox {
  .unsubscribe__checkmark {
    height: 17px;
    width: auto;
    top: 0.2rem;
    left: 0;
    border-radius: 5px;

    &::after {
      content: "";
      @apply absolute hidden;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.unsubscribe ::placeholder {
  @apply !text-primary;
}
</style>
