<template>
  <div
    class="h-full"
    :class="[
      props.showDetail
        ? 'border-[#B2AFAD] border'
        : 'border-[#EEE] border bg-white',
      'py-4 px-[18px] relative rounded-xl',
    ]"
  >
    <div class="pb-[6px] space-x-2" v-if="pointOfSale || serviceCenter">
      <span
        v-if="pointOfSale"
        :class="[
          'bg-[#F5F7F8] text-black pt-[2px] pb-[2px] pl-[10px] pr-[10px]',
          'uppercase rounded-[21px] px-[10px] text-[10px] italic font-medium font-myriad-pro',
        ]"
      >
        {{ pointOfSale }}
      </span>
      <span
        v-if="serviceCenter"
        :class="[
          'bg-[#F5F7F8] text-black  pt-[2px] pb-[2px] pl-[10px] pr-[10px]',
          'uppercase rounded-[21px] px-[10px] text-[10px] font-medium italic font-myriad-pro',
        ]"
      >
        {{ serviceCenter }}
      </span>
    </div>

    <div class="divide-y divide-[#EEE]">
      <div
        v-if="props.info.name"
        class="w-full pb-2 pr-12 text-base font-medium text-black font-myriad-pro"
      >
        {{ props.info.name }}
      </div>

      <div v-if="props.info.address" class="py-2">
        <div
          class="pr-12 w-full text-[#727272] font-semibold text-[12px] font-myriad-pro"
        >
          {{ $t("common.address") }}
        </div>
        <div
          v-if="props.info.address"
          @click="copyClipboard(props.info.address)"
          class="inline-block text-[14px] group font-normal text-black font-myriad-pro leading-[22px]"
        >
          <p class="relative block pr-3 font-normal text-black">
            {{ props.info.address }}
            <span class="absolute inline ml-1 mt-0.5 font-normal">
              <CopyClipboard
                class="hidden cursor-pointer group-hover:inline-block"
              />
            </span>
          </p>
        </div>
      </div>

      <div v-if="props.info.office_hours.weekdays">
        <div
          class="pr-12 pt-2 w-full text-[#727272] font-semibold text-[12px] font-myriad-pro"
        >
          {{ $t("common.monday-to-friday") }}
        </div>
        <div
          class="flex items-center text-sm font-normal text-black font-myriad-pro text-[14px] pb-2"
        >
          {{ props.info.office_hours.weekdays }}
        </div>
      </div>

      <div v-if="props.info.office_hours.weekend">
        <div
          class="pr-12 w-full text-[#727272] font-semibold pt-2 text-[12px] font-myriad-pro"
        >
          {{ $t("common.weekend-saturday-sunday") }}
        </div>

        <div
          v-if="props.info.office_hours.weekend"
          class="flex items-center text-sm font-normal text-black font-myriad-pro text-[14px]"
        >
          {{ props.info.office_hours.weekend }}
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <div
        ref="openMapButton"
        @click="showOnMap"
        @click.stop
        class="flex items-center text-sm font-bold text-black font-myriad-pro text-[13px] leading-[16px] gap-2 cursor-pointer"
      >
        {{ $t("common.open_map") }}
        <OpenMap :color="`black`" />
      </div>
      <div
        v-if="!isDetailsVisible"
        ref="showMoreButton"
        @click.stop
        @click="toggleDetailsVisibility()"
        class="flex items-center text-sm font-bold text-[#959DAA] font-myriad-pro text-[13px] leading-[16px] gap-2 cursor-pointer"
      >
        {{ $t("common.show_more") }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
        >
          <path
            d="M4.38355 5.29251C4.72535 5.63965 5.28043 5.63965 5.62222 5.29251L9.12222 1.73782C9.37379 1.48233 9.44762 1.10187 9.3109 0.768616C9.17418 0.435364 8.85699 0.21875 8.50152 0.21875L1.50152 0.221527C1.14879 0.221527 0.828866 0.438141 0.692147 0.771393C0.555428 1.10465 0.631991 1.48511 0.880819 1.7406L4.38082 5.29529L4.38355 5.29251Z"
            fill="#959DAA"
          />
        </svg>
      </div>
      <div
        ref="showLessButton"
        v-if="isDetailsVisible"
        @click.stop
        @click="toggleDetailsVisibility()"
        class="flex items-center text-sm font-bold text-[#959DAA] font-myriad-pro text-[13px] leading-[16px] gap-2 cursor-pointer"
      >
        {{ $t("common.show_less") }}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
        >
          <path
            d="M4.38355 3.47897C4.72535 3.13184 5.28043 3.13184 5.62222 3.47897L9.12222 7.03366C9.37379 7.28915 9.44762 7.66962 9.3109 8.00287C9.17418 8.33612 8.85699 8.55273 8.50152 8.55273L1.50152 8.54996C1.14879 8.54996 0.828866 8.33334 0.692147 8.00009C0.555428 7.66684 0.631991 7.28638 0.880819 7.03088L4.38082 3.4762L4.38355 3.47897Z"
            fill="#959DAA"
          />
        </svg>
      </div>
    </div>
    <div v-if="isDetailsVisible" class="mt-4">
      <div
        v-if="props.info.phone"
        class="pr-12 w-full text-[#727272] font-semibold pb-1 text-[12px] font-myriad-pro"
      >
        {{ $t("common.user_phone_number") }}
      </div>
      <div
        v-if="props.info.phone"
        @click="copyClipboard(props.info.phone)"
        class="flex items-center group text-sm font-normal text-black font-myriad-pro text-[14px]"
      >
        {{ props.info.phone }}
        <span>
          <CopyClipboard
            v-if="props.info.phone"
            class="hidden w-4 h-4 ml-2 cursor-pointer group-hover:inline-block"
          />
        </span>
      </div>
      <hr
        v-if="props.info.email"
        :class="[
          { '': props.showDetail },
          ' my-[8px] border-[#EEE] text-[#E2E2E2]',
        ]"
      />
      <div
        v-if="props.info.email"
        class="pr-12 w-full text-[#727272] font-semibold pb-1 text-[12px] font-myriad-pro"
      >
        {{ $t("common.email") }}
      </div>
      <div
        v-if="isDetailsVisible"
        @click="copyClipboard(props.info.email)"
        class="flex items-center text-sm font-normal text-black group font-myriad-pro"
      >
        {{ props.info.email }}
        <CopyClipboard
          v-if="props.info.email"
          class="hidden w-4 h-4 ml-2 cursor-pointer group-hover:inline-block"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import CopyClipboard from "~/assets/svg/heroicons/copy-clipboard.svg?component";
import { useTranslation } from "i18next-vue";

const props = defineProps({
  baidu: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
  showDetail: {
    type: Boolean,
    required: true,
  },
  distance: {
    type: Number,
    required: false,
    default: null,
  },
});

const nuxtApp = useNuxtApp();
const { t } = useTranslation();

const isDistributor = ref(true);
const isDetailsVisible = ref(false);
const isDetailsVisibleToggle = ref(false);
const showMoreButton = ref(null);
const openMapButton = ref(null);

const urlToMap = computed(() => {
  return props.baidu
    ? `http://api.map.baidu.com/marker?location=${props.position.lat},${props.position.lng}&output=html&s=gibberish`
    : `https://www.google.com/maps/dir//${props.position.lat},${props.position.lng}`;
});
const pointOfSale = computed(() => {
  let keyName;
  if (props.info.distributor_categories.point_of_sale) {
    keyName = "Store";
  }
  return keyName;
});

const serviceCenter = computed(() => {
  let keyName;
  if (props.info.distributor_categories.service_center) {
    keyName = "Service";
  }
  return keyName;
});

async function copyClipboard(text) {
  try {
    if (!process.browser) return false;

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    }

    nuxtApp.$toast.success(t("common.copied_clipboard"));
  } catch (e) {
    console.error(e);
  }
}
function toggleDetailsVisibility() {
  isDetailsVisibleToggle.value = true;
  isDetailsVisible.value = !isDetailsVisible.value;
}
function showOnMap() {
  window.open(urlToMap.value, "_blank");
}
function distributorCategories() {
  if (props.info.distributor_categories.point_of_sale) {
    return "Store";
  } else if (props.info.distributor_categories.service_center) {
    return "Service";
  } else {
    isDistributor.value = false;
  }
  isDistributor.value = true;
}
</script>
<style scoped>
.address-image::after {
  margin-right: 8px;
  cursor: pointer;
}
</style>
