<template>
  <div
    class="fixed top-auto right-auto flex items-center justify-center md:block inset-x-0 md:left-auto w-full md:absolute md:top-0 md:right-full md:w-[350px] border-none bottom-[60px] md:bottom-auto"
  >
    <client-only>
      <DatePicker
        :value="null"
        v-model.range="date"
        :attributes="attrs"
        :expanded="expanded"
      />
    </client-only>
  </div>
</template>

<script setup>
import { useScreens } from "vue-screen-utils";
import { DatePicker } from "v-calendar";

const emit = defineEmits();
const { mapCurrent } = useScreens({
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
});

const expanded = mapCurrent({ default: false, lg: true }, true);
const attrs = ref([
  {
    key: "today",
    highlight: {
      color: "blue",
      fillMode: "solid",
    },
    dates: new Date(),
  },
  {
    highlight: {
      start: { fillMode: "solid", color: "blue" },
      base: { fillMode: "light", color: "blue" },
      end: { fillMode: "solid", color: "blue" },
    },
  },
]);
const date = ref({
  start: "",
  end: "",
});
watch(
  date,
  (newValue, oldValue) => {
    emit("update-date", newValue);
  },
  { deep: true }
);
</script>
<style>
@import "v-calendar/style.css";

@media only screen and (max-width: 725px) {
  .vc-container {
    margin: 20px !important;
    border: none !important;
    box-shadow: 0 0 10px #ddd !important;
  }
}
.vc-container .vc-blue {
  --vc-accent-600: #13b973 !important;
  --vc-accent-200: #13b97359 !important;
  --vc-accent-900: #ffffff !important;
  --currentColor: #b3c0cf !important;
}
/* .vc-arrows-container {
  padding-left: 25px !important;
  padding-right: 35px !important;
} */
.vc-svg-icon path {
  fill: #b3c0cf !important;
}
.vc-container.vc-expanded {
  border: none !important;
  box-shadow: 0 0 10px #ddd !important;
}
/* .vc-weeks {
  width: 320px !important;
  padding-left: 25px !important;
} */
.vc-container.vc-expanded {
  min-width: 300px !important;
  margin-left: 40px;
}
.vc-day {
  min-height: 40px !important;
}
.vc-day-content:hover {
  color: white !important;
  background-color: #13b973 !important;
}
.vc-day-content {
  @apply font-myriad-pro font-medium text-base w-10 h-10;
}
.vc-highlights .vc-day-box-center-center .vc-highlight {
  border: none !important;
  @apply bg-primary-600 !important;
}
.vc-highlights
  .vc-day-box-center-center
  .vc-highlight.vc-highlight-base-middle {
  @apply bg-[#13b97359] !important;
}
.vc-day-content:focus {
  color: white !important;
  background: #13b973 !important;
}
.vc-highlight,
.vc-weekday {
  @apply w-10 h-10 leading-none;
}
.vc-weekday {
  @apply w-auto pt-5 !important;
}
.vc-day.in-next-month span {
  opacity: 0.5 !important;
}
.vc-monthly .is-not-in-month * {
  @apply opacity-10;
}
</style>
