<template>
  <div class="rounded" :class="!baidu ? 'w-[336px]' : 'w-auto'">
    <div class="flex flex-col" :class="!baidu ? 'px-4 pt-3.5' : ''">
      <div class="py-1 space-x-2" v-if="pointOfSale || serviceCenter">
        <span
          v-if="pointOfSale"
          :class="[
            'bg-[#F4F4F6] text-black pt-[2px] pb-[2px] pl-[10px] pr-[10px]',
            'uppercase rounded-[21px] px-[10px] text-[10px] italic font-medium font-myriad-pro',
          ]"
        >
          {{ pointOfSale }}
        </span>
        <span
          v-if="serviceCenter"
          :class="[
            'bg-[#F4F4F6] text-black  pt-[2px] pb-[2px] pl-[10px] pr-[10px]',
            'uppercase rounded-[21px] px-[10px] text-[10px] font-medium italic font-myriad-pro',
          ]"
        >
          {{ serviceCenter }}
        </span>
      </div>
      <h4
        class="text-[15px] font-medium text-black font-myriad-pro leading-[22px] pr-3 mt-1 mb-0"
      >
        {{ props.info.name }}
      </h4>
    </div>

    <hr class="mx-4 my-2" />

    <div
      v-if="props.showDetail"
      class=""
      :class="!baidu ? 'px-4 pb-[15px]' : ''"
    >
      <ul>
        <li v-if="props.info.office_hours.weekdays" class="mb-[10px]">
          <strong
            class="font-semibold pb-[4px] text-xs font-myriad-pro leading-[12.1px] text-[#4B4B4B]"
          >
            {{ $t("common.monday-to-friday") }}</strong
          >
          <br />
          <span
            class="flex items-center text-sm font-normal text-black font-myriad-pro"
          >
            {{ props.info.office_hours.weekdays }}
          </span>
        </li>
        <li v-if="props.info.office_hours.weekend" class="mb-[10px]">
          <strong
            class="font-semibold pb-[4px] text-xs font-myriad-pro leading-[12.1px] text-[#4B4B4B]"
            >{{ $t("common.weekend-saturday-sunday") }}</strong
          >
          <br />
          <span
            class="flex items-center text-sm font-normal text-black font-myriad-pro"
          >
            {{ props.info.office_hours.weekend }}
          </span>
        </li>
        <li v-if="props.info.phone" class="mb-[18px]">
          <strong
            class="font-semibold pb-[4px] text-xs font-myriad-pro leading-[12.1px] text-[#4B4B4B]"
            >{{ $t("common.user_phone_number") }}</strong
          >
          <br />
          <span
            class="flex items-center text-sm font-normal text-black font-myriad-pro group"
            @click="copyClipboardText(props.info.phone)"
          >
            {{ props.info.phone }}
            <IconsCopyclipboard
              class="hidden w-4 h-4 cursor-pointer group-hover:inline-block"
              icon-id="clip0_1071_18978"
            />
          </span>
        </li>
        <li v-if="props.info.email" class="mb-[18px]">
          <strong
            class="font-semibold pb-[4px] text-xs font-myriad-pro leading-[12.1px] text-[#4B4B4B]"
          >
            {{ $t("common.email") }}
          </strong>
          <br />
          <span
            class="flex items-center text-sm font-normal text-black font-myriad-pro group"
          >
            {{ props.info.email }}
            <span class="hidden group-hover:inline-block ml-1 mt-0.5 w-4 h-4">
              <IconsCopyclipboard
                class="w-4 h-4 cursor-pointer"
                icon-id="clip0_1071_18956"
                @click="copyClipboardText(props.info.address)"
              />
            </span>
          </span>
        </li>
        <li class="mb-[18px]">
          <strong
            class="font-semibold pb-[4px] text-[12px] font-myriad-pro leading-[12.1px] text-[#4B4B4B]"
          >
            {{ $t("common.address") }}
          </strong>
          <br />
          <p
            class="relative block pr-3 text-sm font-normal text-black font-myriad-pro group"
          >
            {{ props.info.address }}
            <span
              class="absolute hidden group-hover:inline-block ml-1 mt-0.5 w-4 h-4"
            >
              <IconsCopyclipboard
                icon-id="clip0_1071_18956"
                @click="copyClipboardText(props.info.address)"
                class="w-4 h-4 cursor-pointer"
              />
            </span>
          </p>
        </li>
      </ul>
      <div class="mt-5">
        <a
          :href="urlToMap"
          target="_blank"
          class="block py-[11px] px-[90px] uppercase text-center text-white whitespace-nowrap text-xs font-medium shadow-sm bg-primary hover:bg-primary-600 hover:text-white rounded-[10px] w-full focus:no-underline"
          v-text="$t('common.open_map')"
        />
        <a
          v-if="mainStore.enableShop"
          :href="$i18nPath('/search?location=')"
          class="flex py-[11px] my-2 no-underline justify-center whitespace-nowrap text-white text-xs font-medium shadow-sm bg-[#2C3032] hover:bg-gray-700 hover:text-white rounded-md w-full focus:no-underline"
          v-text="$t('common.see_inventories')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import { useMainStore } from "@/store/index";

const props = defineProps({
  baidu: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
  showDetail: {
    type: Boolean,
    required: true,
  },
  distance: {
    type: Number,
    required: false,
    default: null,
  },
  activeTab: {
    type: String,
    default: null,
  },
});

const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const mainStore = useMainStore();

const urlToMap = computed(() => {
  return props.baidu
    ? `http://api.map.baidu.com/marker?location=${props.position.lat},${props.position.lng}&output=html&s=gibberish`
    : `https://www.google.com/maps/dir//${props.position.lat},${props.position.lng}`;
});

const pointOfSale = computed(() => {
  let keyName;
  if (props.info.distributor_categories.point_of_sale) {
    keyName = "Store";
  }
  return keyName;
});

const serviceCenter = computed(() => {
  let keyName;
  if (props.info.distributor_categories.service_center) {
    keyName = "Service";
  }
  return keyName;
});

async function copyClipboardText(text) {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    }

    nuxtApp.$toast.success(t("common.copied_clipboard"));
  } catch (e) {
    console.error(e);
  }
}
</script>

<style>
.gm-style-iw-tc::before {
  content: "";
  box-shadow: 0 -25px 0 0 white;
  clip-path: polygon(0 0, 0 100%, 100% 0) !important;
  @apply absolute !bottom-[-24px] rounded-tl-[25px]  w-[18px] h-[36px] left-[47%] bg-transparent;
}
.gm-style-iw-tc::after {
  content: "";
  box-shadow: 0 -25px 0 0 white;
  clip-path: polygon(0 0, 100% 100%, 100% 0) !important;
  @apply absolute !bottom-[-24px] !top-[auto] !left-auto !rounded-tr-[18px] !w-[18px] !h-[36px] !right-[50%] !bg-transparent;
}
.gm-style .gm-style-iw-tc {
  @apply !top-[-1px];
}
.gm-style .gm-style-iw-d {
  max-height: max-content !important;
  @apply overflow-auto !important;
  /* p-2.5 pt-3.5 pb-[15px] */
}
</style>
