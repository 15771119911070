<template>
  <div
    class="flex md:px-4 pt-32 pb-7.5 bg-black news_header__section md:pt-44 md:pb-[35px]"
    id="news-header"
  >
    <div class="container flex items-center justify-between">
      <div class="relative flex flex-col justify-start">
        <div
          class="border-b-4 md:border-b-[5px] -top-6 lg:-top-12 skew-x-[-25deg] w-[67px] md:w-[108px] absolute mb-3"
          style="border-color: #13b973"
        />
        <h2
          v-if="props.title"
          class="max-w-2xl capitalize italic mx-auto text-[32px] font-myriad-pro font-semibold leading-9 md:leading-normal tracking-wide text-white break-words sm:text-4xl lg:leading-[58px] mt-0 md:text-5xl lg:text-6xl lg:text-[68px]"
        >
          {{ props.title }}
        </h2>
      </div>
      <div
        class="flex items-center justify-end md:mt-0"
        v-if="currentRouteName == 'news'"
      >
        <div class="relative hidden mt-0 mr-4 md:block">
          <span
            v-if="filtered"
            class="absolute z-20 w-3 h-3 rounded-full -top-1 -right-1 bg-primary"
          ></span>
          <Dropdown
            align="right"
            :has-overflow-hidden="false"
            @click-outside="clickOutside"
          >
            <template #button-content="{ onClickOpen }">
              <button
                class="flex items-center justify-between dropbtn min-w-[181px] md:min-w-48"
                @click.prevent="() => onToggle(onClickOpen)"
              >
                <span class="flex items-center">
                  <Calendar class="mr-2" />
                  <span class="mt-1">
                    {{ $t("common.select_date") }}
                  </span>
                </span>
                <ArrowDown
                  class="w-5 h-5 ml-2 text-white transition-all duration-100"
                  :class="[dropdownOpen ? 'rotate-180' : '']"
                />
              </button>
            </template>
            <template #default="{ onClickClose }">
              <ul class="!m-0">
                <li class="relative flex items-center px-2 py-3 border-b">
                  <a
                    href="#"
                    @click="setCustomdate('week', onClickClose)"
                    class="font-medium no-underline text-secondary-dark font-myriad-pro hover:text-primary"
                  >
                    {{ $t("common.this_week") }}
                  </a>
                </li>
                <li class="relative flex items-center px-2 py-3 border-b">
                  <a
                    href="#"
                    @click="setCustomdate('3months', onClickClose)"
                    class="font-medium no-underline text-secondary-dark font-myriad-pro hover:text-primary"
                  >
                    {{
                      $t("common.last_months", {
                        month: "3 ",
                        interpolation: { prefix: "[:", suffix: "]" },
                      })
                    }}
                  </a>
                </li>
                <li class="relative flex items-center p-2" @click.stop>
                  <a
                    href="#"
                    @click.stop="setCustomdate('date', onClickClose)"
                    id="calendarlink"
                    class="flex items-center justify-between w-full font-medium no-underline hover:text-primary calendarlink text-secondary-dark font-myriad-pro"
                  >
                    {{ $t("common.custom_date") }}
                    <ArrowDown
                      class="w-5 h-5 ml-2 transition-all duration-100 text-secondary-light"
                      :class="[customDateClicked ? 'rotate-180' : '']"
                    />
                  </a>

                  <Datepicker
                    v-if="customDateClicked"
                    @update-date="updateDate"
                  />
                </li>
                <li v-if="filtered">
                  <Btn
                    class="!w-[-webkit-fill-available] !m-2"
                    size="sm"
                    @click="updateDate"
                    >{{ $t("common.clear_filter") }}
                  </Btn>
                </li>
              </ul>
            </template>
          </Dropdown>
        </div>
        <div class="relative block dropdown md:hidden" id="mobileselect">
          <button
            class="dropbtn menu__button min-w-[181px] flex justify-between items-center"
          >
            <span class="flex items-center font-medium text-[15px]">
              <Calendar class="mr-2" />
              <span class="mt-1">{{ $t("common.select_date") }}</span>
            </span>
            <ArrowDown class="ml-2 !w-[20px]" />
          </button>
        </div>
        <div class="relative hidden md:block">
          <div>
            <ul
              class="relative flex h-[50px] list-none rounded-xl bg-white bg-opacity-10 p-0"
            >
              <li
                class="relative z-30 overflow-hidden text-center flex items-center border-[#dddddd73] border-[1px] rounded-[12px]"
              >
                <a
                  @click="toggleClass"
                  class="z-30 flex items-center justify-center my-1 ml-1.5 font-medium no-underline transition-all ease-in-out border-0 rounded-lg cursor-pointer w-9 h-9 hover:text-black hover:no-underline text-slate-700 bg-inherit"
                  :class="{ 'bg-primary': isActive }"
                >
                  <span class="z-20" v-if="isActive">
                    <ThreeStrokeBlack class="w-[18px] h-[18px]" />
                  </span>
                  <span class="z-20" v-if="!isActive">
                    <ThreeStrokeWhite class="w-[18px] h-[18px]" />
                  </span>
                </a>

                <a
                  @click="toggleClass"
                  :class="[!isActive ? 'bg-primary' : '']"
                  class="z-30 flex items-center justify-center my-1 mr-1.5 font-medium no-underline transition-all ease-in-out border-0 rounded-lg cursor-pointer w-9 h-9 hover:text-black hover:no-underline text-slate-700 bg-inherit"
                >
                  <span class="z-20" v-if="!isActive">
                    <BoxWhite class="w-[18px] h-[18px]" />
                  </span>

                  <span class="z-20" v-if="isActive">
                    <BoxBlack class="w-[18px] h-[18px]" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <slot name="search" />
  </div>
</template>

<script setup>
import Calendar from "@/assets/svg/calendar.svg?component";
import ThreeStrokeBlack from "@/assets/svg/threestrokesblack.svg?component";
import ThreeStrokeWhite from "@/assets/svg/threestrokeswhite.svg?component";
import BoxWhite from "@/assets/svg/boxwhite.svg?component";
import BoxBlack from "@/assets/svg/boxblack.svg?component";
import ArrowUp from "@/assets/svg/heroicons/arrow-up.svg?component";
import ArrowDown from "@/assets/svg/heroicons/arrow-down.svg?component";
import Close from "@/assets/svg/closewhite.svg?component";
import { useMainStore } from "~/store";

const props = defineProps({
  filterInmobile: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: null,
    required: false,
  },
  backgroundImage: {
    type: String,
    default: null,
    required: false,
  },
  view: {
    type: String,
    default: "list",
  },
});

const route = useRoute();
const nuxtApp = useNuxtApp();
const emit = defineEmits();
const store = useMainStore();

const isActive = ref(true);
const customDateClicked = ref(false);
const dropdownOpen = ref(false);
const filtered = ref(false);

watch(
  props,
  (newValue, oldValue) => {
    // console.log(newValue.filterInmobile);
    // filtered.value = newValue.filterInmobile;
  },
  { deep: true }
);

watch(
  props,
  (newValue, oldValue) => {
    if (newValue.view == "list") {
      isActive.val = true;
    } else {
      isActive.val = false;
    }
  },
  { deep: true }
);

const currentRouteName = computed(() => {
  const pageName = nuxtApp.$getKeyByValue(store.pageLinks, route.params.page);
  return pageName;
});

function clickOutside(onClickOpen) {
  dropdownOpen.value = false;
  customDateClicked.value = false;
}

function onToggle(onClickOpen) {
  dropdownOpen.value = !dropdownOpen.value;
  onClickOpen();
}

function updateDate(val) {
  if (!val.start) {
    filtered.value = false;
  } else {
    filtered.value = true;
  }
  document.querySelector(".dropbtn").click();
  customDateClicked.value = false;
  emit("update-date", val);
}

function setCustomdate(type, onClickClose) {
  if (type === "date") {
    customDateClicked.value = !customDateClicked.value;
  } else {
    filtered.value = true;
    customDateClicked.value = false;
    emit("customdateselected", type);
    onClickClose();
  }
}

function toggleClass(event) {
  // Check value
  let view = "list";

  if (isActive.value) {
    view = "card";
  }
  isActive.value = !isActive.value;

  emit("view", view);
}
</script>

<style lang="postcss" scoped>
.news_header__section a {
  text-decoration: none !important;
}

.dropbtn {
  @apply flex h-12 rounded-[10px] cursor-pointer border border-secondary-light text-sm font-myriad-pro p-3 text-white bg-white  bg-opacity-10;
}

.switchli {
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.dropdown {
  position: relative;
}

.dropdown-content {
  border-radius: 5px;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  border-bottom: 1px solid #ccc;
  color: black;
  padding: 12px 16px;
  text-decoration: none !important;
  display: flex;
  padding-right: 10px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.calendarlink:focus {
  color: #13b973;
}
</style>
