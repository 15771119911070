import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { id: "__Frame" }, [
      _createElementVNode("path", {
        id: "__Vector",
        d: "M1 3C1 2.44687 1.44687 2 2 2H14C14.5531 2 15 2.44687 15 3C15 3.55313 14.5531 4 14 4H2C1.44687 4 1 3.55313 1 3ZM3 8C3 7.44688 3.44688 7 4 7H12C12.5531 7 13 7.44688 13 8C13 8.55312 12.5531 9 12 9H4C3.44688 9 3 8.55312 3 8ZM10 13C10 13.5531 9.55313 14 9 14H7C6.44688 14 6 13.5531 6 13C6 12.4469 6.44688 12 7 12H9C9.55313 12 10 12.4469 10 13Z",
        fill: "black"
      })
    ], -1)
  ])))
}
export default { render: render }