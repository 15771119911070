<template>
  <section>
    <HeaderSection :title="title" />
    <div class="container py-6">
      <div v-if="content" class="py-5 static-page" v-html="content" />

      <a
        v-else
        :href="iubendaEmbedUrl"
        class="iubenda-white no-brand iubenda-noiframe iubenda-embed iub-body-embed"
        :title="title"
        v-text="title"
      />
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import { useMainStore } from "~/store";

const nuxtApp = useNuxtApp();
const store = useMainStore();

const iubendaEmbedUrl = ref(null);
const { t } = useTranslation();
const content = ref(null);
const title = ref(t("common.privacy_policy"));
const hasIubendaload = ref(false);
const $config = useRuntimeConfig().public;

if (store.country === "it" || store.country === "cn") {
  try {
    const { data } = await useAPI("api/front/pages/privacy-policy");

    if (data._rawValue && data._rawValue.data) {
      content.value = data._rawValue.data.content;
      title.value = data._rawValue.data.title;
      hasIubendaload.value = false;
    } else {
      await nextTick();
      hasIubendaload.value = true;
      // iubendaEmbedUrl.value = nuxtApp.$loadIubendaEmbedScript("terms");
    }
  } catch (e) {
    console.log(e, "error");
    hasIubendaload.value = true;
  }
}

onMounted(() => {
  if (store.country != "it" || store.country != "cn" || hasIubendaload.value) {
    iubendaEmbedUrl.value = nuxtApp.$loadIubendaEmbedScript("privacy-policy");
  }
});

nuxtApp.$seoModule({
  page_title: title.value,
  meta_name: title.value,
  meta_description: title.value,
  og_name: title.value,
  og_description: title.value,
  model_id: "privacy-policy",
  model_type: "FrontendPage",
});
</script>
