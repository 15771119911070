<template>
  <section>
    <HeaderSection :title="title" />
    <div class="container py-6">
      <div v-if="content" class="py-5 static-page" v-html="content" />
      <a
        v-else
        :href="iubendaEmbedUrl"
        class="iubenda-white no-brand iubenda-noiframe iubenda-embed iub-body-embed"
        :title="title"
        v-text="title"
      />
      <p class="px-4 text-sm md:px-7" v-if="country == 'in'">
        The booking amount paid is non-refundable after 2 years from the date of
        booking.
      </p>
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import { useMainStore } from "~/store";

const nuxtApp = useNuxtApp();
const store = useMainStore();

const iubendaEmbedUrl = ref(null);
const { t } = useTranslation();
const content = ref(null);
const title = ref(t("common.terms_and_conditions"));
const hasIubendaload = ref(false);
const country = ref(store.country);
const $config = useRuntimeConfig().public;

if (country.value === "it" || country.value === "cn") {
  try {
    const { data } = await useAPI("api/front/pages/terms");

    if (data._rawValue && data._rawValue.data) {
      content.value = data._rawValue.data.content;
      title.value = data._rawValue.data.title;
      hasIubendaload.value = false;
    } else {
      await nextTick();
      hasIubendaload.value = true;
    }
  } catch (e) {
    console.log(e, "error");
  }
}

onMounted(() => {
  if (hasIubendaload.value || store.country != "it" || store.country != "cn") {
    iubendaEmbedUrl.value = nuxtApp.$loadIubendaEmbedScript("terms");
  }
});

nuxtApp.$seoModule({
  page_title: title.value,
  meta_name: title.value,
  meta_description: title.value,
  og_name: title.value,
  og_description: title.value,
  model_id: "terms",
  model_type: "FrontendPage",
});
</script>
<style>
#iubenda_policy.iubenda_fluid_policy .iub_container {
  margin-bottom: 0px !important;
}
#iubenda_policy.iubenda_vip_policy .iub_footer {
  padding-bottom: 0px !important;
}
</style>
