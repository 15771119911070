<template>
  <section>
    <HeaderSection :title="$t('common.register')" />
    <div class="container max-w-2xl py-6">
      <form class="mt-6 space-y-3" method="POST" @submit.prevent="onSubmit">
        <ErrorBag :errors="errorsObj" class="mb-4" />
        <div class="flex flex-col md:flex-row gap-x-4">
          <!-- Name -->
          <div class="w-full">
            <label for="given_name">
              {{ $t("common.given_name") }}
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
              >
                <UserIcon />
              </div>
              <input
                id="given_name"
                v-model="given_name"
                type="text"
                name="given_name"
                class="block w-full py-2 mb-6 text-lg border-gray-300 rounded-md ps-10"
                :class="{ 'is-invalid': errorsObj && errorsObj.given_name }"
              />
            </div>
          </div>
          <!-- Surname -->
          <div class="w-full">
            <label for="family_name">
              {{ $t("common.family_name") }}
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
              >
                <UsersIcon />
              </div>
              <input
                id="family_name"
                v-model="family_name"
                type="text"
                name="family_name"
                class="block w-full py-2 mb-6 text-lg border-gray-300 rounded-md ps-10"
                :class="{ 'is-invalid': errorsObj && errorsObj.family_name }"
              />
            </div>
          </div>
        </div>
        <!-- Email -->
        <label for="email">
          {{ $t("common.email") }}
        </label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
          >
            <MailIcon class="w-5 h-5 text-gray-400" />
          </div>
          <input
            id="email"
            v-model="email"
            type="email"
            name="email"
            class="block w-full py-2 mb-6 text-lg border-gray-300 rounded-md ps-10"
            :class="{ 'is-invalid': errorsObj && errorsObj.email }"
          />
        </div>
        <!-- Password -->
        <label for="password">
          {{ $t("common.password") }}
        </label>
        <div class="relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
          >
            <KeyIcon />
          </div>
          <input
            id="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            class="block w-full px-10 py-2 mb-6 text-lg border-gray-300 rounded-md"
            :class="{ 'is-invalid': errorsObj && errorsObj.password }"
          />
          <div
            class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
          >
            <svg
              class="w-5 h-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="showPassword = !showPassword"
            >
              <template v-if="!showPassword">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </template>
              <template v-else>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </template>
            </svg>
          </div>
        </div>
        <!-- Repeat password -->
        <label for="repeatPassword">
          {{ $t("common.password_confirmation") }}
        </label>
        <div class="relative rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
          >
            <KeyIcon />
          </div>
          <input
            id="repeatPassword"
            v-model="password_confirmation"
            :type="showRepeatPassword ? 'text' : 'password'"
            name="repeatPassword"
            class="block w-full px-10 py-2 text-lg border-gray-300 rounded-md"
            :class="{ 'is-invalid': errorsObj && errorsObj.password }"
          />
          <div
            class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
          >
            <svg
              class="w-5 h-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="showRepeatPassword = !showRepeatPassword"
            >
              <template v-if="!showRepeatPassword">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </template>
              <template v-else>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </template>
            </svg>
          </div>
        </div>
        <!-- Register button -->
        <div class="pt-4 pb-12">
          <Btn
            type="submit"
            size="xl"
            class="w-full uppercase disabled:bg-gray-100 disabled:cursor-not-allowed"
            color="primary"
            :disabled="isSending"
          >
            <div class="flex items-center justify-center space-x-2">
              <SpinnerIcon v-if="isSending" class="w-4 h-4 animate-spin" />
              <span class="font-bold text-white uppercase font-myriad-pro">
                {{ $t("common.register") }}</span
              >
            </div>
          </Btn>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import UserIcon from "@/assets/svg/heroicons/user-solid.svg?component";
import UsersIcon from "@/assets/svg/heroicons/users-solid.svg?component";
import MailIcon from "@/assets/svg/heroicons/mail-solid.svg?component";
import KeyIcon from "@/assets/svg/heroicons/key-solid.svg?component";
import SpinnerIcon from "~/assets/svg/spinner.svg?component";
import { useMainStore } from "~/store";
import { useTranslation } from "i18next-vue";

definePageMeta({
  middleware: "guest",
});

const nuxtApp = useNuxtApp();
const mainStore = useMainStore();
const route = useRoute();
const $i18nPath = nuxtApp.$i18nPath;
const { t } = useTranslation();

if (!mainStore.enableShop) {
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}

const isSending = ref(false);
let errorsObj = reactive({});
const given_name = ref(null);
const family_name = ref(null);
const email = ref(null);
const password = ref(null);
const password_confirmation = ref(null);
const showPassword = ref(false);
const showRepeatPassword = ref(false);

async function onSubmit() {
  errorsObj = {};
  isSending.value = true;

  try {
    await useAPI("api/auth/signup", {
      given_name: given_name.value,
      family_name: family_name.value,
      email: email.value,
      company: mainStore.country,
      password: password.value,
      password_confirmation: password_confirmation.value,
    });

    isSending.value = false;

    if (window.gtag) {
      window.gtag("event", "sign_up");
    }

    route.replace({
      path: $i18nPath("/login"),
      query: {
        email: email.value,
      },
    });
  } catch (e) {
    errorsObj = e.response.data.errors;
    isSending.value = false;
  }
}

useHead({
  title: t("common.register"),
});
</script>
