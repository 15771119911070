<template>
  <section>
    <HeaderSection :title="title" />
    <div class="container py-6">
      <!-- eslint-disable-next-line-->
      <div v-html="content" class="static-page" />
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
const $config = useRuntimeConfig().public;
const title = ref(t("common.recovery_mode"));
const content = ref(null);
try {
  const { data } = await useAPI("api/front/pages/recovery");

  if (data._rawValue.data) {
    content.value = data._rawValue.data.content;
    title.value = data._rawValue.data.title;
  }
} catch (error) {
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
}
useNuxtApp().$seoModule({
  page_title: title.value,
  meta_name: title.value,
  meta_description: title.value,
  og_name: title.value,
  og_description: title.value,
  model_id: "recovery",
  model_type: "FrontendPage",
});
</script>
