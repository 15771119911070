<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clip-path="url(#clip0_4487_3303)">
      <path
        id="Vector"
        d="M4 5.33337H28"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M5.33301 5.33337V18.6667C5.33301 19.374 5.61396 20.0522 6.11406 20.5523C6.61415 21.0524 7.29243 21.3334 7.99967 21.3334H23.9997C24.7069 21.3334 25.3852 21.0524 25.8853 20.5523C26.3854 20.0522 26.6663 19.374 26.6663 18.6667V5.33337"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M16 21.3334V26.6667"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M12 26.6666H20"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M10.667 16L14.667 12L17.3337 14.6666L21.3337 10.6666"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4487_3303">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
