<template>
  <section>
    <HeaderSection
      :title="title"
      :description="
        store.country == 'ar' && store.locale == 'es'
          ? 'Para vehículos comercializados a partir del 1/1/2023'
          : ''
      "
    />
    <div class="container py-6">
      <!-- eslint-disable-next-line-->
      <div class="py-5 static-page" v-html="content" />
    </div>
  </section>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import { useMainStore } from "~/store";

const { t } = useTranslation();
const store = useMainStore();
const title = ref(t("common.warranty"));
const content = ref(null);
const $config = useRuntimeConfig().public;

try {
  const { data } = await useAPI("api/front/pages/warranty");
  if (data._rawValue.data) {
    content.value = data._rawValue.data.content;
    title.value = data._rawValue.data.title;
  }
} catch (e) {
  error({ statusCode: 404 });
}

useNuxtApp().$seoModule({
  page_title: title.value,
  meta_name: title.value,
  meta_description: title.value,
  og_name: title.value,
  og_description: title.value,
  model_id: "warranty",
  model_type: "FrontendPage",
});
</script>
