<template>
  <section class="relative w-full">
    <!-- Desktop version Banner cover Section -->
    <div
      class="relative md:block hidden w-full overflow-hidden md:bg-center bg-no-repeat bg-right md:bg-contain about-us-hero-bg-section h-[480px] md:h-[850px] lg:h-[600px] xl:h-[742px] 2.5xl:h-[960px] 3.8xl:h-[1024px] xl:block"
      :style="backgroundImageStyle"
    >
      <div>
        <div
          class="container md:pt-[543px] lg:pt-[143px] 1.5xl:pt-[164px] 2.5xl:pt-[190px] md:pb-[100px] lg:pb-0 xl:pb-[84px]"
        >
          <div
            class="flex flex-col text-center md:items-center lg:justify-start lg:items-start md:justify-center"
          >
            <div
              class="border-b-4 skew-x-[-50deg] xl:w-[108px] md:w-[77.353px] lg:w-[84px] w-[86px] h-1 lg:h-1.5 xl:absolute md:mt-5"
              style="border-color: #13b973"
            />
            <!-- lg:mt-[99px] -->
            <h1
              class="text-[28px] italic inline lg:text-[48px] leading-[34px] sm:text-center sm:w-[450px] lg:text-left xl:text-[66px] xl:pt-[24px] font-myriad-pro font-semibold w-[299px] md:leading-[42px] md:text-4xl lg:w-[510px] xl:w-[524px] lg:leading-[54px] xl:leading-[58px] text-white lg:mt-[10%] lg:mt-[41px] sm:mt-[24px] sm:text-[36px]"
              v-html="
                $t('common.about_us_main_title', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            />

            <p
              class="text-[15px] md:text-xl pt-2 lg:text-[26px] lg:leading-[normal] italic xl:text-[31px] font-myriad-pro w-[299px] sm:w-[428px] lg:w-[450px] xl:w-[540px] leading-normal font-normal md:text-center lg:text-start text-white md:pt-6"
              v-html="
                $t('common.about_us_main_description', {
                  benelli_brand: 'Benelli',
                  interpolation: { prefix: '[:', suffix: ']' },
                })
              "
            ></p>
          </div>

          <div
            class="w-full absolute xl:bottom-[80px] md:bottom-[40px] flex flex-col-reverse lg:mt-0 sm:items-center sm:justify-center lg:justify-start lg:items-start"
          >
            <ImgLazyLoader src="/about-us/about-us-slogan.png" alt="Slogan" />
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Version Banner cover Section-->
    <div class="block lg:hidden md:hidden">
      <div
        class="relative"
        data-aos="fade-left"
        data-aos-delay="100"
        data-aos-offset="100"
      >
        <img
          class="relative w-full h-[480px] object-cover object-center"
          :src="`/about-us${folderName}/about-us-hero-section-img.png`"
          alt=""
          loading="lazy"
        />
        <div
          class="absolute flex w-full items-center justify-center bottom-[32px] px-4"
        >
          <ImgLazyLoader src="/about-us/about-us-slogan.png" alt="Slogan" />
        </div>
      </div>
      <div
        style="background: #191919"
        class="flex items-center justify-start order-2 px-4 pt-[30px] pb-[60px] overflow-hidden md:w-1/2"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <div class="w-auto">
          <div
            class="border-b-4 w-[86px] skew-x-[-50deg] flex justify-start items-start text-start h-1 lg:h-1.5 mb-[16px]"
            style="border-color: #13b973"
          />
          <h1
            class="text-[32px] inline font-myriad-pro italic font-semibold w-[345px] sm:w-full tracking-[-2%] leading-[38px] text-start text-white"
            v-html="
              $t('common.about_us_main_title', {
                benelli_brand: 'Benelli',
                interpolation: { prefix: '[:', suffix: ']' },
              })
            "
          ></h1>
          <p
            class="text-[15px] mt-[10px] text-white leading-[24px] font-myriad-pro italic font-normal"
            v-html="
              $t('common.about_us_main_description', {
                benelli_brand: 'Benelli',
                interpolation: { prefix: '[:', suffix: ']' },
              })
            "
          ></p>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
const folderName = ref("/screen-1440");

const backgroundImageStyle = computed(() => {
  return {
    backgroundImage: `url('/about-us${folderName.value}/about-us-hero-section-img.png')`,
  };
});

if (process.browser) {
  if (window.innerWidth >= 768 && window.innerWidth < 1024) {
    folderName.value = "/screen-768";
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    folderName.value = "/screen-1024";
  } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
    folderName.value = "/screen-1440";
  } else if (window.innerWidth >= 1920 && window.innerWidth < 2500) {
    folderName.value = "/screen-1920";
  } else if (window.innerWidth >= 2500) {
    folderName.value = "/screen-2500";
  } else {
    folderName.value = "/screen-375";
  }
  window.addEventListener("resize", onResize);
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

function onResize() {
  if (window.innerWidth >= 768 && window.innerWidth < 1024) {
    folderName.value = "/screen-768";
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    folderName.value = "/screen-1024";
  } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
    folderName.value = "/screen-1440";
  } else if (window.innerWidth >= 1920 && window.innerWidth < 2500) {
    folderName.value = "/screen-1920";
  } else if (window.innerWidth >= 2500) {
    folderName.value = "/screen-2500";
  } else {
    folderName.value = "/screen-375";
  }
}
</script>
<style>
.about-us-hero-bg-section {
  background-repeat: no-repeat;
  background-position: center center;
  @apply md:bg-cover;
}
.about-us-hero-bg-section h1 span {
  @apply uppercase;
}
</style>
