<template>
  <section v-if="state.faqs.length">
    <div class="relative w-full bg-black">
      <div class="xl:py-[100px] py-0 pt-[30px] pb-[60px] container">
        <div v-for="(faq, faqIndex) in state.faqs" :key="faqIndex">
          <div v-if="faq.published">
            <h1
              class="text-primary-500 text-[26px] text-center md:text-left xl:text-[33px] leading-[34px] font-semibold italic xl:leading-[48px] font-myriad-pro uppercase"
            >
              {{ faq.title }}
            </h1>

            <div class="xl:mt-10 mt-[26px]">
              <!-- Support -->
              <Accordion class="m-0 space-y-4 list-none xl:space-y-3">
                <template #default="{ activeIndex, handleAccordion }">
                  <Collapsible
                    v-for="(item, index) in faq.items"
                    :aria-label="item.question"
                    :key="index"
                    :is-expanded="activeIndex(index) || item.toggle"
                    :on-click="() => handleAccordion(index)"
                    class="border-b border-[#EDEDED] mb-4"
                  >
                    <template #trigger="{ isActive }">
                      <div
                        class="flex items-center justify-between w-full mt-2"
                      >
                        <h3
                          class="mb-[10px] text-[18px] xl:text-[20px] leading-[28px] xl:leading-6 font-bold xl:font-bold text-white font-myriad-pro-condensed"
                        >
                          {{ item.question }}
                        </h3>
                        <div
                          class="w-[40px] h-[40px] ml-2 p-[10px] flex items-center rounded-full mb-4 bg-[#F4F4F4]"
                        >
                          <CloseIcon
                            v-if="isActive"
                            class="inline text-black w-[20px] h-[20px]"
                          />
                          <PlusIcon
                            v-else
                            class="inline text-black w-[20px] h-[20px]"
                          />
                        </div>
                      </div>
                    </template>
                    <div
                      class="mb-6 text-sm leading-6 font-normal text-white xl:text-base md:mr-[60px] font-myriad-pro"
                      v-html="item.answer"
                    ></div>
                  </Collapsible>
                </template>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import PlusIcon from "@/assets/svg/heroicons/plus.svg?component";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";

const state = reactive({ faqs: [] });

loadFaqs();

async function loadFaqs() {
  try {
    const { data } = await useAPI("api/front/faqs?type=dealer");

    data._rawValue &&
      data._rawValue.data.forEach((element) => {
        const items = [];
        if (element.items) {
          element.items.forEach((item, index) => {
            let itemObj = {};
            const toggle = !!isVisible(element.settings.display_type, index);
            itemObj = {
              ...item,
              toggle,
            };
            items.push(itemObj);
          });
        }
        const faqObj = {
          ...element,
          items,
        };
        state.faqs = [...state.faqs, faqObj];
      });
  } catch (e) {
    // console.log(e, "fetching error");
  }
}

function isVisible(type, index) {
  if (type === "Display first one" && index === 0) {
    return index;
  } else if (type === "Display all") {
    return index;
  } else {
    return false;
  }
}
</script>
