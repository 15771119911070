<template>
  <section>
    <!-- Password sent -->
    <template v-if="sent">
      <h2 class="p-4 mb-6 text-2xl font-normal border-b border-gray-200">
        {{ $t("common.forgot_password_email_sent") }}
      </h2>
      <p class="px-4">
        {{ $t("common.forgot_password_email_sent_steps") }}
      </p>
      <div class="p-4">
        <Btn
          color="black"
          class="w-full mt-4 uppercase"
          @click.prevent="$emit('close')"
          v-text="$t('common.ok')"
        />
      </div>
    </template>
    <!-- Password recover input -->
    <template v-else>
      <div :show="isSending">
        <h2 class="p-4 mb-6 text-2xl font-normal border-b border-gray-200">
          {{ $t("common.forgot_password") }}
        </h2>
        <p class="px-4">
          {{ $t("common.we_will_send_link_create_new") }}
        </p>

        <div class="px-4" :show="isSending">
          <form class="mt-6 space-y-3" method="POST" @submit.prevent="onSubmit">
            <label for="emailRecover">
              {{ $t("common.email") }}
            </label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <div
                class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
              >
                <MailIcon />
              </div>
              <input
                id="emailRecover"
                v-model="email"
                type="email"
                name="email"
                class="block w-full py-2 text-lg border-gray-300 rounded-md ps-10"
              />
            </div>
          </form>

          <!-- Error -->
          <ErrorBag class="mt-2" :errors="errorsObj" />
        </div>

        <!-- Cancel/Submit buttons -->
        <div class="px-4 py-4 text-end border-top md:px-0 md:me-4">
          <Btn
            color="white"
            class="block w-full mt-3 uppercase border-0 md:w-32 disabled:bg-gray-100 disabled:cursor-not-allowed"
            :disabled="isSending"
            @click.prevent="$emit('close')"
            v-text="$t('common.cancel')"
          />
          <Btn
            class="w-full mt-4 uppercase md:w-32 bg-primary-500 md:ms-2 disabled:bg-gray-100 disabled:cursor-not-allowed"
            :disabled="isSending"
            @click.prevent="submit"
            v-text="$t('common.submit')"
          />
        </div>
      </div>
    </template>
  </section>
</template>

<script setup>
import MailIcon from "~/assets/svg/heroicons/mail-solid.svg?component";
import { useMainStore } from "~/store";

const email = ref("");
const isSending = ref(false);
const sent = ref(false);
let errorsObj = reactive({});
const mainStore = useMainStore();

async function submit() {
  isSending.value = true;
  errorsObj = {};

  try {
    await useAPI("api/auth/password/create", {
      body: {
        email: email.value,
        country: mainStore.country,
        locale: mainStore.locale,
      },
    });

    setTimeout(() => {
      isSending.value = false;
      sent.value = true;
    }, 300);
  } catch (error) {
    console.error(error, "error");

    if (!error.response.data) {
      throw error;
    } else {
      errorsObj = error.response.data.errors;
    }

    setTimeout(() => {
      isSending.value = false;
    }, 300);
  }
}
</script>
