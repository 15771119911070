<template>
  <div>
    <slot :handleAccordion="handleAccordion" :activeIndex="isActive" />
  </div>
</template>
<script setup>
const index = ref(null);

function handleAccordion(selectedIndex) {
  index.value = selectedIndex;
}
function isActive(activeIndex) {
  return index.value === activeIndex;
}
</script>
