<template>
  <MapsBaiduMap v-if="store.country === 'cn'" />
  <MapsGoogleMap v-else />
</template>

<script setup>
import { useMainStore } from "@/store/index";
import { useLocaleStore } from "@/store/locales";

const store = useMainStore();
const localeStore = useLocaleStore();
const nuxtApp = useNuxtApp();

onMounted(() => {
  if (nuxtApp.$intercom) {
    nuxtApp.$intercom.update({ hide_default_launcher: true });
  }
});
onBeforeUnmount(() => {
  if (nuxtApp.$intercom) {
    nuxtApp.$intercom.update({ hide_default_launcher: false });
  }
});
</script>
