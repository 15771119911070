<template>
  <div
    class="flex pt-32 bg-black manuals_header__section pb-5 md:pt-44 md:pb-[35px]"
    id="manuals-header"
  >
    <div class="container relative flex items-center justify-between w-full">
      <div class="relative flex flex-col justify-start">
        <div
          class="border-b-4 md:border-b-[5px] -top-6 lg:-top-12 skew-x-[-25deg] w-[67px] md:w-[108px] absolute mb-3"
          style="border-color: #13b973"
        />
        <h2
          v-if="title"
          class="max-w-2xl italic mx-auto text-[32px] font-myriad-pro capitalize font-semibold leading-9 md:leading-normal text-white sm:text-4xl lg:leading-[58px] mt-0 md:text-5xl lg:text-6xl lg:text-[68px]"
        >
          {{ title }}
        </h2>
      </div>
      <div class="flex items-center space-x-[12px] justify-end">
        <div
          :class="
            showSearch ? 'absolute bg-[#1a1a1a] inset-x-4 z-[100]' : 'relative'
          "
        >
          <div
            :class="
              showSearch
                ? 'bg-[#1a1a1a] w-full'
                : 'bg-[#FFFFFF] bg-opacity-10 w-[48px] md:w-[320px]'
            "
            class="relative h-[48px] rounded-lg shadow-sm"
            style="border: 1px solid rgba(255, 255, 255, 0.3)"
          >
            <SearchIcon
              class="absolute top-[14px] left-[14px] md:block hidden"
            />
            <SearchIcon
              class="absolute top-[14px] left-[14px] block md:hidden"
              @click="showSearch = !showSearch"
            />
            <input
              v-model="search"
              id="search"
              name="search"
              placeholder="Search"
              :class="showSearch ? 'inline-block' : 'hidden md:inline-block'"
              class="w-full h-[48px] focus-visible:outline-none px-[44px] py-[14px] !border-0 bg-opacity-0 bg-transparent text-white"
              @input="updateFilter"
            />
            <CloseIcon
              v-if="showSearch"
              class="absolute top-[15px] right-[15px] text-white w-[20px] h-[20px]"
              @click="closeFilter"
            />
          </div>
        </div>
        <div>
          <div>
            <ul
              class="relative flex h-[48px] list-none rounded-xl bg-white bg-opacity-10"
            >
              <li
                class="relative z-30 overflow-hidden text-center flex border-[#dddddd73] border-[1px] rounded-[12px]"
              >
                <a
                  @click="toggleClass"
                  class="z-30 flex items-center justify-center my-[4.5px] mx-1.5 font-medium no-underline transition-all ease-in-out border-0 rounded-lg cursor-pointer w-9 h-9 hover:text-black hover:no-underline text-slate-700 bg-inherit"
                  :class="{ 'bg-primary': isActive }"
                >
                  <span class="z-20" v-if="isActive">
                    <ThreeStrokeBlack class="w-[18px] h-[18px]" />
                  </span>
                  <span class="z-20" v-if="!isActive">
                    <ThreeStrokeWhite class="w-[18px] h-[18px]" />
                  </span>
                </a>

                <a
                  @click="toggleClass"
                  :class="[!isActive ? 'bg-primary' : '']"
                  class="z-30 flex items-center justify-center my-[4.5px] mx-1.5 font-medium no-underline transition-all ease-in-out border-0 rounded-lg cursor-pointer w-9 h-9 hover:text-black hover:no-underline text-slate-700 bg-inherit"
                >
                  <span class="z-20" v-if="!isActive"
                    ><BoxWhite class="w-[18px] h-[18px]"
                  /></span>

                  <span class="z-20" v-if="isActive"
                    ><BoxBlack class="w-[18px] h-[18px]" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <slot name="search" />
  </div>
</template>

<script setup>
import ThreeStrokeBlack from "@/assets/svg/threestrokesblack.svg?component";
import ThreeStrokeWhite from "@/assets/svg/threestrokeswhite.svg?component";
import BoxWhite from "@/assets/svg/boxwhite.svg?component";
import BoxBlack from "@/assets/svg/boxblack.svg?component";
import ArrowUp from "@/assets/svg/heroicons/arrow-up.svg?component";
import ArrowDown from "@/assets/svg/heroicons/arrow-down.svg?component";
import Close from "@/assets/svg/closewhite.svg?component";
import SearchIcon from "~/assets/svg/heroicons/manual-search.svg?component";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import { debounce } from "lodash-es";

const props = defineProps({
  filterInmobile: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: null,
    required: false,
  },
  backgroundImage: {
    type: String,
    default: null,
    required: false,
  },
  view: {
    type: String,
    default: "list",
  },
});

const emit = defineEmits();

const isActive = ref(true);
const dropdownOpen = ref(false);
const filtered = ref(false);
const search = ref("");
const showSearch = ref(false);
const windowWidth = ref(0);
const isMobile = ref(false);

watch(
  props,
  (newValue, oldValue) => {
    if (props.filterInmobile != filtered.value) {
      filtered.value = props.filterInmobile;
    }
    if (props.view == "list") {
      isActive.value = true;
    } else {
      isActive.value = false;
    }
  },
  { deep: true }
);
onMounted(async () => {
  if (import.meta.browser) {
    windowWidth.value = window.innerWidth;
    if (windowWidth.value <= 767) {
      isMobile.value = true;
    } else {
      isMobile.value = false;
      showSearch.value = false;
    }
    await nextTick();
    window.addEventListener("resize", onResize);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

function onToggle(onClickOpen) {
  dropdownOpen.value = !dropdownOpen.value;
  onClickOpen();
}

function toggleClass(event) {
  // Check value
  let view = "list";

  if (isActive.value) {
    view = "card";
  }
  isActive.value = !isActive.value;

  emit("view", view);
}
function closeFilter() {
  showSearch.value = false;
  filtered.value = false;
  emit("filter", "");
}

function updateFilter(e) {
  if (!e.target.value) {
    filtered.value = false;
  } else {
    filtered.value = true;
  }

  request(e);
}

const request = debounce(function (e) {
  emit("filter", e.target.value);
}, 900);

function onResize() {
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 767) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
    showSearch.value = false;
  }
}
</script>

<style lang="postcss" scoped>
.manuals_header__section a {
  text-decoration: none !important;
}

.dropbtn {
  @apply flex h-12 rounded-[10px] cursor-pointer border border-secondary-light text-sm font-myriad-pro p-3 text-white bg-white  bg-opacity-10;
}

.switchli {
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.dropdown {
  position: relative;
}

.dropdown-content {
  border-radius: 5px;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  border-bottom: 1px solid #ccc;
  color: black;
  padding: 12px 16px;
  text-decoration: none !important;
  display: flex;
  padding-right: 10px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.calendarlink:focus {
  color: #13b973;
}
</style>
