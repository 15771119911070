<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clip-path="url(#clip0_4487_3315)">
      <path
        id="Vector"
        d="M4 25.3334C8.444 22.6667 10.6667 20 10.6667 17.3334C10.6667 13.3334 9.33333 13.3334 8 13.3334C6.66667 13.3334 5.29067 14.78 5.33333 17.3334C5.37867 20.064 7.544 21.1694 8.66667 22.6667C10.6667 25.3334 12 26 13.3333 24C14.2227 22.6667 14.8893 21.556 15.3333 20.6667C16.6667 23.7774 18.444 25.3334 20.6667 25.3334H24"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M26.6663 22.6667V6.66667C26.6663 5.172 25.4943 4 23.9997 4C22.505 4 21.333 5.172 21.333 6.66667V22.6667L23.9997 25.3333L26.6663 22.6667Z"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M21.333 9.33337H26.6663"
        stroke="#13B973"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4487_3315">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
