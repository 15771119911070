<template>
  <div v-bind="$attrs">
    <div id="panorama" class="relative">
      <ul
        id="scenes"
        class="absolute bottom-1 md:bottom-4 transform start-1/2 -translate-s-1/2 z-[9999] space-s-1 md:space-s-4 flex items-center"
      >
        <li>
          <button
            class="focus:outline-none rounded-sm p-0.5 bg-black shadow-md overflow-hidden transform transition ease duration-200 scale-75 hover:scale-100 hover:bg-primary-500"
            @click.prevent="pannellum.loadScene('outdoor')"
          >
            <div
              :style="{
                backgroundImage: `url(${$config.public.CDN_DOMAIN}/museum/outdoor/0.jpg)`,
              }"
              class="w-12 h-12 p-1 bg-white bg-cover rounded-sm md:h-28 md:w-28"
            />
          </button>
        </li>
        <li>
          <button
            class="focus:outline-none rounded-sm p-0.5 bg-black shadow-md overflow-hidden transform transition ease duration-200 scale-75 hover:scale-100 hover:bg-primary-500"
            @click.prevent="pannellum.loadScene('mainRoom')"
          >
            <div
              :style="{
                backgroundImage: `url(${$config.public.CDN_DOMAIN}/museum/main-room/0.jpg)`,
              }"
              class="w-12 h-12 p-1 bg-white bg-cover rounded-sm md:h-28 md:w-28"
            />
          </button>
        </li>
        <li>
          <button
            class="focus:outline-none rounded-sm p-0.5 bg-black shadow-md overflow-hidden transform transition ease duration-200 scale-75 hover:scale-100 hover:bg-primary-500"
            @click.prevent="pannellum.loadScene('giuseppeRoom')"
          >
            <div
              :style="{
                backgroundImage: `url(${$config.public.CDN_DOMAIN}/museum/giuseppe-room/0.jpg)`,
              }"
              class="w-12 h-12 p-1 bg-white bg-cover rounded-sm md:h-28 md:w-28"
            />
          </button>
        </li>
        <li>
          <button
            class="focus:outline-none rounded-sm p-0.5 bg-black shadow-md overflow-hidden transform transition ease duration-200 scale-75 hover:scale-100 hover:bg-primary-500"
            @click.prevent="pannellum.loadScene('motobiRoom')"
          >
            <div
              :style="{
                backgroundImage: `url(${$config.public.CDN_DOMAIN}/museum/motobi-room/0.jpg)`,
              }"
              class="w-12 h-12 p-1 bg-white bg-cover rounded-sm md:h-28 md:w-28"
            />
          </button>
        </li>
        <li>
          <button
            class="focus:outline-none rounded-sm p-0.5 bg-black shadow-md overflow-hidden transform transition ease duration-200 scale-75 hover:scale-100 hover:bg-primary-500"
            @click.prevent="pannellum.loadScene('leoncinoRoom')"
          >
            <div
              :style="{
                backgroundImage: `url(${$config.public.CDN_DOMAIN}/museum/leoncino-room/0.jpg)`,
              }"
              class="w-12 h-12 p-1 bg-white bg-cover rounded-sm md:h-28 md:w-28"
            />
          </button>
        </li>
      </ul>
    </div>
    <div class="container my-8">
      <article class="mx-auto prose text-center xl:prose-xl max-w-none">
        {{ $t("common.view_more") }}:
        <a
          href="https://www.officinebenelli.it/?utm_source=www&utm_medium=page&utm_campaign=pesaro_museum"
          target="_blank"
          rel="nofollow"
        >
          Officine Benelli
        </a>
      </article>
    </div>
  </div>
</template>

<script setup>
const pannellum = ref(null);

const $config = useRuntimeConfig();

function init() {
  if (!import.meta.browser || !import.meta.client) {
    return;
  }

  pannellum.value = window.pannellum.viewer("panorama", {
    default: {
      firstScene: "outdoor",
      sceneFadeDuration: 1000,
      autoLoad: true,
      autoRotate: true,
      autoRotateInactivityDelay: 3000,
      mouseZoom: "fullscreenonly",
    },

    scenes: {
      outdoor: {
        type: "cubemap",
        preview: `${$config.public.CDN_DOMAIN}/museum/outdoor/preview.jpg`,
        cubeMap: [
          `${$config.public.CDN_DOMAIN}/museum/outdoor/0.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/outdoor/1.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/outdoor/2.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/outdoor/3.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/outdoor/4.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/outdoor/5.jpg`,
        ],
        hotSpots: [
          {
            pitch: -5,
            yaw: 3,
            type: "scene",
            text: "Enter",
            sceneId: "mainRoom",
          },
        ],
      },

      mainRoom: {
        type: "cubemap",
        preview: `${$config.public.CDN_DOMAIN}/museum/main-room/preview.jpg`,
        cubeMap: [
          `${$config.public.CDN_DOMAIN}/museum/main-room/0.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/main-room/1.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/main-room/2.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/main-room/3.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/main-room/4.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/main-room/5.jpg`,
        ],
        hotSpots: [
          {
            pitch: -5,
            yaw: 32,
            type: "scene",
            text: "Sala Giuseppe Benelli",
            sceneId: "giuseppeRoom",
          },
        ],
      },

      giuseppeRoom: {
        type: "cubemap",
        preview: `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/preview.jpg`,
        cubeMap: [
          `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/0.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/1.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/2.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/3.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/4.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/giuseppe-room/5.jpg`,
        ],
      },

      motobiRoom: {
        type: "cubemap",
        preview: `${$config.public.CDN_DOMAIN}/museum/motobi-room/preview.jpg`,
        cubeMap: [
          `${$config.public.CDN_DOMAIN}/museum/motobi-room/0.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/motobi-room/1.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/motobi-room/2.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/motobi-room/3.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/motobi-room/4.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/motobi-room/5.jpg`,
        ],
      },

      leoncinoRoom: {
        type: "cubemap",
        preview: `${$config.public.CDN_DOMAIN}/museum/leoncino-room/preview.jpg`,
        cubeMap: [
          `${$config.public.CDN_DOMAIN}/museum/leoncino-room/0.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/leoncino-room/1.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/leoncino-room/2.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/leoncino-room/3.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/leoncino-room/4.jpg`,
          `${$config.public.CDN_DOMAIN}/museum/leoncino-room/5.jpg`,
        ],
      },
    },
  });
}

useHead({
  script: [
    {
      src: "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.js",
      callback: () => setTimeout(() => init(), 200),
    },
  ],
  link: [
    {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/pannellum@2.5.6/build/pannellum.css",
    },
  ],
});
</script>

<style>
#panorama {
  width: 100vw;
  height: calc(100vh - 96px);
}
.pnlm-controls-container {
  margin-top: 95px;
}
</style>
