<template>
  <div
    class="relative rounded-md bg-[#F4F5F6] group"
    v-if="view == 'card' && post"
  >
    <img
      v-if="post.cover"
      :src="post.cover.url"
      class="w-full h-[230px] md:h-[226px] object-cover"
      alt="Cover Image"
    />
    <img
      v-else
      src="../assets/images/no-pdf.png"
      alt="no-pdf"
      class="w-full h-[230px] md:h-[226px]"
    />

    <div class="pt-[14px] px-[14px] pb-5">
      <p
        v-text="post.name"
        class="font-semibold font-myriad-pro text-lg leading-[30px] leading-[30px] text-[#000]"
      ></p>

      <div class="flex items-start mt-3 space-x-6">
        <button
          @click="
            downloadFile(
              post.files[0] && post.files[0].url,
              post.files[0] && post.files[0].file_name
            )
          "
        >
          <DownloadIcon class="text-[#929292] hover:text-primary" />
        </button>

        <span class="mx-[14px] h-5 w-[1px] bg-black opacity-10 inline-block">
        </span>
        <a
          :href="post.files[0] && post.files[0].url"
          target="_blank"
          rel="manual pdf"
          class="cursor-pointer"
        >
          <ArrowIcon class="text-[#929292] hover:text-primary" />
        </a>
      </div>
    </div>
  </div>

  <div v-else-if="view == 'list' && post" class="relative group">
    <div
      class="flex items-center justify-between space-x-[24px] border-b border-[#EAEDF3] py-[10px]"
    >
      <!-- <a
        v-if="post.files"
        :href="post.files[0] && post.files[0].url"
        target="_blank"
        rel="manual pdf"
      >
        <img
          v-if="post.cover"
          :src="post.cover && post.cover.url"
          class="w-[64px] h-[42px]"
          alt="Cover Image"
        />
     
      </a> -->
      <p
        v-text="post.name"
        class="font-semibold font-myriad-pro text-lg leading-[30px] leading-[30px] text-[#000]"
      ></p>

      <div class="flex items-center justify-center space-x-6">
        <button
          @click="
            downloadFile(
              post.files[0] && post.files[0].url,
              post.files[0] && post.files[0].file_name
            )
          "
        >
          <DownloadIcon class="text-[#929292] hover:text-primary" />
        </button>

        <a
          :href="post.files[0] && post.files[0].url"
          target="_blank"
          rel="manual pdf"
          class="cursor-pointer"
        >
          <ArrowIcon class="text-[#929292] hover:text-primary" />
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import DownloadIcon from "~/assets/svg/heroicons/manual-download.svg?component";
import ArrowIcon from "~/assets/svg/heroicons/manual-arrow.svg?component";

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  view: {
    default: "card",
    type: String,
  },
});

async function downloadFile(fileURL, fileName) {
  try {
    const response = await fetch(fileURL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName; // Replace with your desired file name

    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("There was an error!", error);
  }
}
</script>
