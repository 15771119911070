<template>
  <section>
    <HeaderSection :title="$t('common.login')" />
    <div class="container max-w-xl py-6">
      <form class="mt-6 space-y-3" method="POST" @submit.prevent="onSubmit">
        <!-- Error -->
        <ErrorBag :errors="errorsObj" class="mb-4" />
        <!-- Email input -->
        <label for="email">
          {{ $t("common.email") }}
        </label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
          >
            <MailIcon class="w-5 h-5 text-gray-400" />
          </div>
          <input
            id="email"
            v-model="email"
            type="email"
            name="email"
            :class="{ 'is-invalid': hasErrors }"
            class="block w-full py-2 mb-6 text-lg border-gray-300 rounded-md ps-10"
          />
        </div>
        <!-- Password input -->
        <label for="password">
          {{ $t("common.password") }}
        </label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div
            class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
          >
            <KeyIcon />
          </div>
          <input
            id="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            :class="{ 'is-invalid': hasErrors }"
            class="block w-full px-10 py-2 text-lg border-gray-300 rounded-md"
          />
          <div
            class="absolute inset-y-0 flex items-center cursor-pointer end-0 pe-3"
          >
            <svg
              class="w-5 h-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="showPassword = !showPassword"
            >
              <template v-if="!showPassword">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </template>
              <template v-else>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </template>
            </svg>
          </div>
        </div>
        <!-- Recover password -->
        <div class="text-end">
          <a
            class="cursor-pointer hover:no-underline"
            @click.prevent="(e) => openResetPasswordModal()"
          >
            {{ $t("common.forgot_password") }}
          </a>
        </div>
        <!-- Login -->
        <div class="pt-2">
          <Btn
            type="submit"
            size="xl"
            class="w-full uppercase disabled:bg-gray-100 disabled:cursor-not-allowed"
            color="primary"
            :disabled="isSending"
          >
            <div class="flex items-center justify-center space-x-2">
              <SpinnerIcon v-if="isSending" class="w-4 h-4 animate-spin" />
              <span class="font-bold text-white uppercase font-myriad-pro">
                {{ $t("common.login") }}</span
              >
            </div>
          </Btn>
        </div>
        <!-- Register -->
        <div>
          <nuxt-link
            :to="$i18nPath('/register')"
            class="block mt-3 text-center uppercase hover:no-underline"
            :prefetch="false"
          >
            {{ $t("common.create_an_account") }}
          </nuxt-link>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import MailIcon from "@/assets/svg/heroicons/mail-solid.svg?component";
import KeyIcon from "@/assets/svg/heroicons/key-solid.svg?component";
import SpinnerIcon from "~/assets/svg/spinner.svg?component";
import ResetPasswordModal from "~/modals/ResetPasswordModal";
import { useMainStore } from "~/store";
import { useAuthStore } from "~/store/auth";
import { useTranslation } from "i18next-vue";
import { useModal } from "vue-final-modal";

definePageMeta({
  middleware: "guest",
});

const mainStore = useMainStore();
const authStore = useAuthStore();
const route = useRoute();
const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const { $cookies } = nuxtApp;
const $i18nPath = nuxtApp.$i18nPath;

const email = ref(null);
const password = ref(null);
const showPassword = ref(false);
const isSending = ref(false);
let errorsObj = reactive({});

if (!mainStore.enableShop) {
  throw createError({
    statusCode: 404,
    statusMessage: "Page Not Found",
  });
} else {
  email.value = route.query || null;
}

const hasErrors = computed(() => {
  return Object.keys(errorsObj).length > 0;
});

const { open, close } = useModal({
  component: ResetPasswordModal,
});
const openResetPasswordModal = open;

async function onSubmit() {
  isSending.value = true;
  errorsObj = {};

  try {
    await authStore.login({
      email: email.value,
      password: password.value,
      remember_me: true,
    });

    if (window.gtag) {
      await window.gtag("event", "login");
    }
    if (nuxtApp.$intercom) {
      nuxtApp.$intercom.update({
        user_id: authStore.user.id,
        name: authStore.user.given_name,
        email: authStore.user.email,
      });
    }

    const redirect = $cookies.get("Redirect");

    if (redirect && redirect !== route.fullPath) {
      $cookies.remove("Redirect");
      await route.push(redirect);
    } else {
      await route.push($i18nPath("/"));
    }
  } catch (e) {
    if (!e.response.data) {
      throw e;
    } else if (e.response && e.response.status === 403) {
      errorsObj = {
        email: [e.response.data.message],
      };
    } else {
      errorsObj = e.response.data.errors;
    }
  } finally {
    isSending.value = false;
  }
}

useHead({
  title: t("common.test_drive"),
});
</script>
