import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#__clip0_4549_6614)" }, [
      _createElementVNode("path", {
        d: "M2.25 5.25C2.25 4.85218 2.40804 4.47064 2.68934 4.18934C2.97064 3.90804 3.35218 3.75 3.75 3.75H14.25C14.6478 3.75 15.0294 3.90804 15.3107 4.18934C15.592 4.47064 15.75 4.85218 15.75 5.25V12.75C15.75 13.1478 15.592 13.5294 15.3107 13.8107C15.0294 14.092 14.6478 14.25 14.25 14.25H3.75C3.35218 14.25 2.97064 14.092 2.68934 13.8107C2.40804 13.5294 2.25 13.1478 2.25 12.75V5.25Z",
        stroke: "white",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "__clip0_4549_6614" }, [
        _createElementVNode("rect", {
          width: "18",
          height: "18",
          fill: "white"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }