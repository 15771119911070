import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "22",
  viewBox: "0 0 22 22",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#__clip0_10202_24750)\"><path d=\"M3.66602 15.5834V17.4167C3.66602 17.9029 3.85917 18.3693 4.20299 18.7131C4.5468 19.0569 5.01312 19.25 5.49935 19.25H16.4993C16.9856 19.25 17.4519 19.0569 17.7957 18.7131C18.1395 18.3693 18.3327 17.9029 18.3327 17.4167V15.5834\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M6.41602 10.0834L10.9993 14.6667L15.5827 10.0834\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M11 3.66663V14.6666\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g><defs><clipPath id=\"__clip0_10202_24750\"><rect width=\"22\" height=\"22\" fill=\"white\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }