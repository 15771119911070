<template>
  <div>
    <BecomeHeroSection
      :title="$t('common.become_dealer.become_a')"
      :secondaryTitle="$t('common.become_dealer.dealer')"
      :description="
        $t('common.become_dealer.hero_section_description', {
          brand_name: 'Benelli ',
          country: defaultContryName,
          interpolation: { prefix: '[:', suffix: ']' },
        })
      "
      imageUrl="dealer"
    />
    <BecomeSubHeroSection
      :title="$t('common.become_dealer.completing_initial_application_title')"
      :description="
        $t('common.become_dealer.completing_initial_application_description')
      "
      :userContent="{
        title: $t('common.become_dealer.meeting_requirements_title'),
        description: $t(
          'common.become_dealer.meeting_requirements_description',
          {
            brand_name: 'Benelli ',
            interpolation: { prefix: '[:', suffix: ']' },
          }
        ),
      }"
      :screenContent="{
        title: $t('common.become_dealer.presenting_title'),
        description: $t('common.become_dealer.presenting_description', {
          brand_name: 'Benelli ',
          interpolation: { prefix: '[:', suffix: ']' },
        }),
      }"
      :contractContent="{
        title: $t('common.become_dealer.signing_title'),
        description: $t('common.become_dealer.signing_description', {
          brand_name: 'Benelli ',
          interpolation: { prefix: '[:', suffix: ']' },
        }),
      }"
    />
    <BecomeFaqs />
    <BecomeContactUs
      :title="$t('common.become_dealer.contact_us_section_title')"
      :formFieldsName="{
        your_name: $t('common.become_dealer.your_name'),
        your_company: $t('common.become_dealer.your_company'),
        your_role: $t('common.become_dealer.your_role'),
        phone_number: $t('common.become_dealer.phone_number'),
        address: $t('common.become_dealer.address'),
        message: $t('common.become_dealer.message'),
        phone_placeholder: $t('common.become_dealer.phone_placeholder'),
        become_dealer_thanks: $t('common.become_dealer.become_dealer_thanks'),
        country: $t('common.country'),
      }"
      imageFolder="dealer"
    />
  </div>
</template>
<script setup>
import { useLocaleStore } from "~/store/locales";
import { useTranslation } from "i18next-vue";

const localeStore = useLocaleStore();
const { t } = useTranslation();

const defaultContryName = localeStore.getDefaultContryName;
</script>
